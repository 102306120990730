/** @jsx jsx */
import { jsx } from '@emotion/core';
import { SiteLink } from 'components';
import { CartSummary } from '../cartSummary/summary';
import { useAuth } from 'auth';
import { LoginForm } from 'auth/controls/login/loginForm';
import { CreateAccountForm } from 'auth/controls/createAccount/createAccountForm';
import { DetailsForm } from './detailsForm';

export function Shipping({ cart, saveShipping, post }) {
  const auth = useAuth();
  const needLogin = hasGuideInOrder(cart) && !auth.user;

  return (
    <div>
      <div className='row'>
        <div className='col-md-9'>
          {needLogin ? (
            <div>
              <p className='font-bold'>
                Create your account first to continue. If you already have an account, please login instead.
              </p>
              <div className='row'>
                <div className='col-md-6 mb-8 md:mb-0'>
                  <div className=''>
                    <h3>CREATE ACCOUNT</h3>
                    <CreateAccountForm />
                  </div>
                </div>
                <div className='col-md-6 border-gray-300 border-l border-none md:border-solid'>
                  <div className=''>
                    <h3>LOG IN</h3>
                    <LoginForm />
                    <div style={{ marginTop: 20 }}>
                      <SiteLink to={'/member/forgotPassword'}>Forgot Password?</SiteLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div>
              <DetailsForm cart={cart} saveShipping={saveShipping} />
            </div>
          )}
        </div>

        <div className='col-md-3'>
          <CartSummary cart={cart} />
        </div>
      </div>
    </div>
  );
}

function hasGuideInOrder(cart) {
  return cart.orderItems.some((x) => x.ext?.itemType === 'guide');
}
