export const priceData = {
  standard: {
    id: 'standard',
    name: 'Standard',
    monthly: 7.99,
    yearly: 56.99
  },
  pro: {
    id: 'pro',
    name: 'Professional',
    monthly: 18.99,
    yearly: 134.99
  }
}
