import React from 'react';
import env from 'env';
import { useGet, useRouter } from 'components';
// import { SiteLink } from 'components';
import { Loading, ErrorPopup } from 'components';
import { useAuth } from 'auth';
import { CategoryTiles } from 'site/members/category/categoryTiles';

export function DashboardLoading({version = 'v1'}) {
  const [sendingStatus, setSendingStatus] = React.useState(null);
  const auth = useAuth();
  const get = useGet();
  const { query } = useRouter();
  const setCorporate = query.setCorporate || '';
  //console.log("version ---- ", version);
  if (setCorporate) {
    localStorage.setItem('corporateId', setCorporate);
  }
  //console.log("localStorage.getItem('corporateId')", localStorage.getItem('corporateId'));
  React.useEffect(() => {
    get.send(env.apiBase + `/api/member/getDashboard?corporateId=${localStorage.getItem('corporateId')}`);
    setSendingStatus('pending');
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    if (sendingStatus === 'done') auth.setMemberInfo(data.memberInfo);
    // eslint-disable-next-line
  }, [sendingStatus]);

  if (get.loading()) {
    return <Loading />;
  } else if (get.hasErrors()) {
    let errors = get.errors;
    let title = 'Loading Error';
    if (get.containsErrorMessage('inactive account')) {
      title = 'Account Disabled';
      errors = 'This account is inactive or temporarily disabled.';
    }
    return <ErrorPopup title={title} errors={errors} />;
  }

  const data = get.response;

  if (get.done() && sendingStatus === 'pending') {
    setSendingStatus('done');
  }
  console.log("dfdsfdffd-----", data);
  return <CategoryTiles categories={version === 'v2' ? data.newCategories : data.categories} />;
}