import React from 'react';
import env from 'env';
import { usePost } from 'components';
import { SiteLink } from 'components';
import { useRouter } from 'components';
import { Loading, ErrorPopup } from 'components';
import { Button, ErrorMessage } from 'components';
import { IoReturnUpBack } from 'react-icons/io5';

export function CreateAccountLoading() {
  const { query } = useRouter();
  const token = (query && query.u) || '';
  const post = usePost();

  React.useEffect(() => {
    post.send(env.apiBase + '/api/profile/validateToken', { token: token });
    // eslint-disable-next-line
  }, []);

  if (post.loading()) {
    return (
      <Loading>
        <div style={{ marginTop: '2rem', textAlign: 'center' }}>Validating Request...</div>
      </Loading>
    );
  } else if (post.hasErrors()) {
    return (
      <ErrorPopup title='Loading Error' errors={post.errors}>
        <IoReturnUpBack size={30} /> Go back to{' '}
        <SiteLink to='/member/login'>
          <span>LOG IN</span>
        </SiteLink>
      </ErrorPopup>
    );
  }

  const data = post.response?.results || {};
  return <CreateAccount token={token} data={data} />;
}

export function CreateAccount({ token, data }) {
  const [password, setPassword] = React.useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');
  const [errors, setErrors] = React.useState([]);

  const post = usePost();

  function onSubmit(e) {
    e.preventDefault();
    if (password.length === 0) {
      setErrors(['Password is required.']);
      return;
    }
    if (password !== confirmPassword) {
      setErrors([`Password and Confirm Password don't match.`]);
      return;
    }

    post.send(env.apiBase + '/api/profile/createAccount', {
      token: token,
      password: password,
      confirmPassword: confirmPassword,
    });
  }

  React.useEffect(() => {
    if (post.done()) {
      window.setTimeout(() => (window.location.href = '/member/login'), 1500);
    }
  }, [post]);

  if (!data.corporateId) return <div className='text-red-600'>Invalid link - no corporate linked.</div>;

  return (
    <div className='forgot-password auth-panel' style={{ maxWidth: '600px', width: '90%', margin: 'auto' }}>
      <h1>Create an account</h1>
      <div className='loading-panel-holder'>
        <div className='panel-content'>
          <div className='my-2'>
            Email: <b>{data.email}</b>
          </div>
          <form method='POST' noValidate='' onSubmit={onSubmit}>
            <div className='form-group'>
              <label htmlFor='password'>New Password</label>
              <input
                id='password'
                type='password'
                className='form-control'
                name='password'
                required
                autoComplete='false'
                onChange={(event) => {
                  setPassword(event.target.value);
                }}
              />
              <div className='invalid-feedback'>Password is invalid</div>
            </div>
            <div className='form-group'>
              <label htmlFor='confirmPassword'>Confirm Password</label>
              <input
                id='confirmPassword'
                type='password'
                className='form-control'
                name='confirmPassword'
                required
                autoComplete='false'
                onChange={(event) => {
                  setConfirmPassword(event.target.value);
                }}
              />
              <div className='invalid-feedback'>Confirm Password is invalid</div>
            </div>
            <div style={{ marginTop: '1rem' }}>
              <div className='form-group m-0'>
                <Button onClick={onSubmit} type='submit' status={post.status}>
                  Set Password
                </Button>
                {post.done() && (
                  <div style={{ color: 'chocolate', marginTop: '0.5rem' }}>
                    <b>Successfully created your account. Please wait while page is loading...</b>
                  </div>
                )}
              </div>
            </div>
            <ErrorMessage errors={errors} />
            <ErrorMessage errors={post.errors} />
          </form>
        </div>
      </div>

      <div style={{ marginTop: '2rem' }}>
        <IoReturnUpBack size={30} /> Go back to{' '}
        <SiteLink to='/member/login'>
          <span>LOG IN</span>
        </SiteLink>
      </div>
    </div>
  );
}
