export const promoStyle = {
  promoSection: {
    margin: '1rem 0',
    padding: '0.5rem 1rem',
    background: '#fff5e2',
  },
  promoCode: {
    input: {
      textTransform: 'uppercase',
    },
    '.form-group': {
      margin: 0,
    },
  },
};
