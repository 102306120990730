import React from 'react';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { FieldError, ServerErrors } from '@site/_helpers';

import env from 'env';
import { ErrorMessage } from 'components';
import { usePost } from 'components';
import { useStore } from 'store';
import { useRouter } from 'components';

const schema = yup.object().shape({
  email: yup.string().nullable().required('Email is required.'),
  firstName: yup.string().nullable().required('First Name is required.'),
  surname: yup.string().nullable().required('Surname is required.'),
  phone: yup.string().nullable(),
  numberOfTickets: yup
    .number()
    .nullable()
    .typeError('Number of Tickets must be a number')
    .required('Please provide Number of Tickets.')
    .min(1, 'Number of Tickets must be more than 1.'),
});

export function RegisterForm({ event, onClosed }) {
  const post = usePost();
  const { dispatch } = useStore();
  const { push } = useRouter();
  const [registered, setRegistered] = React.useState(false);
  const [currentCartItems, setCurrentCartItems] = React.useState(0);
  const defaultValues = event.register || {};

  const useAddToCart = event.eventType === 0 /*PurchaseTicket*/ && event.price;

  const {
    formState: { isSubmitting, errors },
    register,
    handleSubmit,
    reset,
  } = useForm({ defaultValues, resolver: yupResolver(schema) });
  const [serverErrors, setServerErrors] = React.useState([]);

  async function onSubmit(model) {
    try {
      if (useAddToCart) {
        post.send(env.apiBase + '/api/event/addToCart', {
          eventId: event.eventId,
          register: model,
          cartId: env.getDevCartId(),
        });
      } else {
        post.send(env.apiBase + '/api/event/register', { eventId: event.eventId, register: model });
      }
    } catch (e) {
      reset(model);
      setServerErrors(e);
    }
  }

  React.useEffect(() => {
    if (post.done()) {
      post.reset();
      reset({ numberOfTickets: 1 });

      const useAddToCart = event.eventType === 0 /*PurchaseTicket*/ && event.price;
      if (useAddToCart) {
        const cartInfo = post.response.results.cartInfo;
        console.log(cartInfo);
        env.setDevCartId(cartInfo.cartId);
        dispatch({
          type: 'SET_CART',
          payload: cartInfo,
        });

        setRegistered(true);
        setCurrentCartItems(cartInfo.numberOfItems);
      } else {
        setRegistered(true);
      }
    }
  }, [dispatch, post, reset, post.status, event]);

  // console.log(isValid, dirtyFields, isSubmitting, errors);
  return (
    <div className=''>
      <form method='POST' noValidate='' onSubmit={handleSubmit(onSubmit)}>
        <div className='row'>
          <div className='col-md-6'>
            <div className='form-group'>
              <label htmlFor='firstName'>First Name</label>
              <input id='firstName' type='text' className='form-control' {...register('firstName')} />
              <FieldError error={errors?.firstName} />
            </div>
          </div>
          <div className='col-md-6'>
            <div className='form-group'>
              <label htmlFor='surname'>Surname</label>
              <input id='surname' type='text' className='form-control' {...register('surname')} />
              <FieldError error={errors?.surname} />
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-6'>
            <div className='form-group'>
              <label htmlFor='email'>Email</label>
              <input id='email' type='email' className='form-control' {...register('email')} />
              <FieldError error={errors?.email} />
            </div>
          </div>
          <div className='col-md-6'>
            <div className='form-group'>
              <label htmlFor='phone'>Phone</label>
              <input id='phone' type='text' className='form-control' {...register('phone')} />
              <FieldError error={errors?.phone} />
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-6'>
            <div className='form-group'>
              <label htmlFor='numberOfTickets'>Number of Tickets</label>
              <input
                id='numberOfTickets'
                type='number'
                className='form-control'
                style={{ width: '5em' }}
                {...register('numberOfTickets')}
              />
              <FieldError error={errors?.numberOfTickets} />
            </div>
          </div>
        </div>

        <div className='form-group m-8 text-center'>
          {useAddToCart ? (
            registered ? (
              <>
                <div>
                  <button type='submit' disabled={isSubmitting} className='btn btn-primary'>
                    {isSubmitting && (
                      <span className='spinner-grow spinner-grow-sm' role='status' aria-hidden='true'></span>
                    )}
                    <span className=''>ADD TO CART</span>
                  </button>
                  <button className='btn btn-warning btn-sm ml-2' onClick={() => push('/cart')}>
                    GO TO CART
                  </button>
                </div>
                <div>
                  <p style={{ color: '#0e76bc' }}>
                    Successfully added. Total {currentCartItems} {currentCartItems > 1 ? 'items' : 'item'} in the cart.{' '}
                    <br />
                    You can keep adding more registrations on this page.
                  </p>
                </div>
              </>
            ) : (
              <button type='submit' disabled={isSubmitting} className='btn btn-primary'>
                {isSubmitting && (
                  <span className='spinner-grow spinner-grow-sm' role='status' aria-hidden='true'></span>
                )}
                <span className=''>ADD TO CART</span>
              </button>
            )
          ) : registered ? (
            <div>
              <p className='text-red-500'>Successfully submitted.</p>
              <p className='text-red-500'>A confirmation email has been sent. Please check your inbox.</p>
            </div>
          ) : (
            <button type='submit' disabled={isSubmitting} className='btn btn-primary'>
              {isSubmitting && <span className='spinner-grow spinner-grow-sm' role='status' aria-hidden='true'></span>}
              <span className=''>SUBMIT</span>
            </button>
          )}

          <button className='btn btn-link btn-sm ml-2' onClick={onClosed}>
            {registered ? 'CLOSE' : 'CANCEL'}
          </button>
        </div>

        <ServerErrors errors={serverErrors} />
        <ErrorMessage errors={post.errors} />
      </form>
    </div>
  );
}
