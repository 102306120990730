/** @jsx jsx */
import { jsx } from '@emotion/core';
import style from './summaryStyle';

import utils from '../../../utils';

export function CartSummary({ cart }) {
  //console.log('cart', cart);
  const formatMoney = utils.money.formatMoney;

  return (
    <div css={style.summary}>
      {/* {
      cart.shippingMethod === "Standard" &&
      <Fragment>
        <div css={style.totalHdr}> Shipping </div>
        <div css={style.totalAmount}> AU {formatMoney(cart.shippingAmount)} </div>
        <hr style={{borderTop:'1px solid white'}} />
      </Fragment>
    } */}
      <div css={style.totalHdr}> Total Amount </div>
      <div css={style.totalAmount}> AU {formatMoney(cart.totalAmount, 2)} </div>
      {cart.promoInfo && cart.promoInfo.promoCode && (
        <span>
          <b style={{ color: 'red' }}>
            -{utils.money.formatMoney(cart.promoInfo.totalItemDiscount + cart.promoInfo.totalTaxDiscount)}
          </b>{' '}
          saved
        </span>
      )}
      {/* <div class="row">
    <div class="col-md-6">Total:</div>
    <div class="col-md-6">AU {formatMoney(cart.itemAmount)}</div>
  </div>
  <div class="row">
    <div class="col-md-6">GST:</div>
    <div class="col-md-6">AU {formatMoney(cart.taxAmount)}</div>
  </div> */}
      {/* <div class="row">
    <div class="col-md-6">Shipping:</div>
    <div class="col-md-6">
      <span *ngIf="!shippingMethod">Method not selected yet</span>
      <span *ngIf="shippingMethod == 'Pickup'">Pickup - FREE</span>
      <span *ngIf="shippingMethod == 'Shipping'">{{cart.shippingAmount > 0? 'AU ' + formatMoney(cart.shippingAmount) : 'FREE'}}</span>
    </div>
  </div>
  <div class="row total">
    <div class="col-md-6"></div>
    <div class="col-md-6">
      <span>{{cart.shippingMethod == ''? 'Please select Shipping Option.': 'AU ' + formatMoney(cart.totalAmount)}}</span>
    </div>
  </div>
 */}
    </div>
  );
}
