import { initialState as initialState_, reducer as reducer_ } from '../lib/store/reducers';
export { StoreContext, StoreProvider, useStore } from '../lib/store';

const initialState = Object.assign(initialState_, { cartInfo: null });

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_CART':
      return {
        ...state,
        cartInfo: action.payload,
      };
    default:
      return reducer_(state, action);
  }
};

export { initialState, reducer };
