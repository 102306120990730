import React from 'react';
import { useAuth } from 'auth';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { FieldError, ServerErrors } from '@site/_helpers';

const schema = yup.object().shape({
  email: yup.string().required('Email is required.'),
  password: yup.string().required('Password is required.').min(6, 'Password is too short - should be 6 chars minimum.'),
});

export function LoginForm(props) {
  const auth = useAuth();

  const {
    formState: { isSubmitting, errors },
    register,
    handleSubmit,
    reset,
  } = useForm({ resolver: yupResolver(schema) });
  const [serverErrors, setServerErrors] = React.useState([]);

  async function onSubmit(model) {
    try {
      await auth.signin(model.email, model.password);
    } catch (e) {
      reset(model);
      setServerErrors(e);
    }
  }

  // console.log(isValid, dirtyFields, isSubmitting, errors);
  return (
    <div className=''>
      <form method='POST' noValidate='' onSubmit={handleSubmit(onSubmit)}>
        <div className='form-group'>
          <label htmlFor='email'>Email</label>
          <input id='email' type='email' className='form-control' {...register('email')} />
          <FieldError error={errors?.email} />
        </div>
        <div className='form-group'>
          <label htmlFor='password'>Password</label>
          <input id='password' type='password' className='form-control' {...register('password')} />
          <FieldError error={errors?.password} />
        </div>
        <div className='form-group m-0'>
          <button type='submit' disabled={isSubmitting} className='btn btn-primary btn-block'>
            {isSubmitting && <span className='spinner-grow spinner-grow-sm' role='status' aria-hidden='true'></span>}
            <span className=''>Log In</span>
          </button>
        </div>

        <ServerErrors errors={serverErrors} />
      </form>
    </div>
  );
}
