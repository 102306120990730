/** @jsx jsx */
import { jsx } from '@emotion/core'

import { Link } from 'react-router-dom';
import { useAuth } from 'auth';
import { useRouter } from 'components';

export function MemberMenu({ onCloseMenu }) {
  const auth = useAuth();
  const router = useRouter();

  function signout(e) {
    e.preventDefault();
    e.stopPropagation();

    auth.signout().then(user => {
      router.push('/member/login')
      onCloseMenu();
    }).catch(error => {
      console.log(error)
    })
  }

  // const email = (auth.user && auth.user.email) || '';

  //if (router.path === '/member/login') return null;
  if (!auth.isResolved) return null;

  const s = getStyle()

  if (auth.user) {
    return <ul>
      <li className={`navBar__item`}>
        <Link css={s.memberLink} className="navBar__link" to="/member/dashboard" onClick={onCloseMenu}><span>Dashboard</span></Link>
      </li>
      <li className={`navBar__item`}>
        <Link css={s.memberLink} className="navBar__link" to="/" onClick={(e) => signout(e)}><span>LOG OUT</span></Link>
      </li>
    </ul>
  } else {
    return <ul>
      <li className={`navBar__item`}>
        <Link css={s.memberLink} className="navBar__link" to="/member/login" onClick={onCloseMenu}><span>LOG IN</span></Link>
      </li>
    </ul>
  }
}

function getStyle() {
  return {
    memberLink: {
      color: '#b3ffa4 !important',
    }
  }
}