/** @jsx jsx */
import { jsx } from '@emotion/core'
import style from './payStyle';
import React from 'react';

import { usePost } from 'components';
import { Button, ErrorMessage } from 'components';
import env from 'env';
import { useRouter } from 'components';
import { initStripe } from './payStripe';

import { priceData } from '../_priceData'
import { MonthlyOrYearly } from '../monthlyOrYearly'

export function Pay({ payVM, getSelection }) {

  const [per, setPer] = React.useState('monthly');
  const [stripe, setStripe] = React.useState(null);
  const [card, setCard] = React.useState(null);
  const [error, setError] = React.useState(null);

  const post = usePost();
  const { Redirect } = useRouter()

  React.useEffect(() => {
    const { stripe, card } = initStripe(setError)
    setStripe(stripe);
    setCard(card);
  }, [])

  function payNow() {
    if (error) return;
    stripe.createToken(card).then(function (result) {
      if (result.error) {
        setError(result.error.message);
      } else {
        setError(null);
        // Send the token to your server.
        onSubmit(result.token.id);
      }
    });
  }

  function onSubmit(tokenId) {
    const data = payVM; //getSelection();

    console.log(tokenId, data)
    post.send(env.apiBase + "/api/subscription/payStripe", { ...data, per, tokenId: tokenId, cartId: env.getDevCartId() });
  }

  if (post.done()) {
    env.setDevCartId(null);
    return <Redirect to={`/order-thankyou?o=${post.response.results.OrderIdToken}`} />
  }

  const data = payVM; //getSelection()
  const subsType = priceData[data.subscriptionType]
  // console.log(data, subsType)


  return <div css={style.pay}>
    <div className="row">
      <div className="col-12">
        {subsType ? <div>
          <p>You have selected <b>'{subsType.name}'</b>. Please choose your payment frequency.</p>
          <MonthlyOrYearly subsType={subsType} per={per} setPer={setPer} />
        </div> : <div>No subscription selected. Please go back to the previouse steps.</div>}


        <div css={style.stripeWrapper}>
          <div className="stripe-wrapper">
            <div id="card-element"></div>
          </div>
          {/* <div css = {style.paymentInfoText}>
            Payment through encrypted gateway can be made by Visa, Mastercard or AMEX.  Please enter your credit card details to complete your order. 
            For alternative payment methods please <SiteLink to = "#contactForm" className="contact-link"> contact us </SiteLink>
          </div> */}
        </div>

      </div>

    </div>
    <div css={style.formActions}>
      <Button onClick={payNow} status={post.status}>Pay Now</Button>
      <ErrorMessage errors={error} />
      <ErrorMessage errors={post.errors} />
    </div>
  </div>
}
