
import { renderRule as code_dashboard } from './code-dashboard/render';
import { renderRule as code_dashboardV2 } from './code-dashboardV2/render';
import { renderRule as code_instagram } from './code-instagram/render';
import { renderRule as code_subscribe } from './code-subscribe/render';
import { renderRule as ecmProductList } from './ecmProductList/render';
import { renderRule as eventList } from './eventList/render';
import { renderRule as guideList } from './guideList/render';
import { renderRule as html } from './html/render';
import { renderRule as imagePanel } from './imagePanel/render';
import { renderRule as memberDashboard } from './memberDashboard/render';
import { renderRule as memberDashboardV2 } from './memberDashboardV2/render';
import { renderRule as newCategoryList } from './newCategoryList/render';
import { renderRule as pageTiles } from './pageTiles/render';
import { renderRule as panel } from './panel/render';
import { renderRule as photoGallery } from './photoGallery/render';
import { renderRule as sharingIcons } from './sharingIcons/render';
import { renderRule as signupForm } from './signupForm/render';
import { renderRule as videoPanel } from './videoPanel/render';
export const items = [
  code_dashboard,
  code_dashboardV2,
  code_instagram,
  code_subscribe,
  ecmProductList,
  eventList,
  guideList,
  html,
  imagePanel,
  memberDashboard,
  memberDashboardV2,
  newCategoryList,
  pageTiles,
  panel,
  photoGallery,
  sharingIcons,
  signupForm,
  videoPanel,
]