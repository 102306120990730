import { useState, useEffect } from 'react';
import { useGet } from 'components';
import env from 'env';
import { useStore } from 'store';

export function CartLoader() {
  const { state, dispatch } = useStore();
  const [loadingStatus, setLoadingStatus] = useState('ready');
  const [data, setData] = useState(null);

  const get = useGet();

  useEffect(() => {
    if (loadingStatus !== 'ready') return;
    if (state && !state.menu) {
      setLoadingStatus('loading');
      get.send(env.apiBase + '/api/cart/getcart/' + env.getDevCartId());
    }
    // eslint-disable-next-line
  }, [state]);

  useEffect(() => {
    console.log(data);
    if (data) {
      dispatch({ type: 'SET_CART', payload: data.cartInfo });
    }
    // eslint-disable-next-line
  }, [data]);

  if (loadingStatus !== 'done' && get.done()) {
    setLoadingStatus('done');
    setData(get.response);
  }
  return null;
}
