import React from 'react';
import env from 'env';
import { useGet } from 'components';
import { Loading } from 'components';
import { useRouter } from '../components';
import { useAuth } from 'auth';
import { NoPermission } from 'components/notFound/noPermission';

const PermissionCheckFirst = ({ children, ...rest }) => {
  const isCancelled = React.useRef(false);
  const [sessionChecked, setSessionChecked] = React.useState(false);
  const { pathname, navigate, Redirect } = useRouter();
  const auth = useAuth();
  const get = useGet();

  React.useEffect(() => {
    const checkAuth = async () => {
      if (!isCancelled.current) {
        const loggedIn = await auth.loggedIn();
        // console.log({ loggedIn })
        if (!loggedIn) {
          navigate('/member/login');
        } else {
          setSessionChecked(true);
          if (get.status === 'ready')
            get.send(env.apiBase + '/api/member/canAccess?url=' + encodeURIComponent(pathname));
        }
      }
    };
    checkAuth();
    return () => {
      isCancelled.current = true;
    };
    // eslint-disable-next-line
  }, [auth, navigate]);

  if (!sessionChecked || !get.done()) {
    return (
      <div>
        <Loading />
      </div>
    );
  } else {
    console.log(get.response);
    if (get.response.redirectUrl) {
      return <Redirect to={get.response.redirectUrl} />;
    } else if (get.response.accessResultCode === 98 /*No Auth*/) {
      return <Redirect to='/member/login' />;
    } else if (get.response.accessResultCode === 99 /*No Permission*/) {
      return <NoPermission />;
    } else {
      return <div>{children}</div>;
    }
  }
};

export default PermissionCheckFirst;