/** @jsx jsx */
import { jsx } from '@emotion/core';
import style from './stepStyle';
import React from 'react';

// import { FaAngleRight } from 'react-icons/fa';

export function StepNav({ stepInfo }) {
  const { steps, currentStep, moveTo, canGo } = stepInfo;

  //if (currentStep === steps.length - 1) return null;
  return (
    <div css={style.navs}>
      {steps
        .filter((x) => x.id !== 'thanks')
        .map((step, index) => (
          <React.Fragment key={step.id}>
            <div
              css={[
                style.nav,
                step.filled && style.navFilled,
                index === currentStep ? style.navCurrent : null,
                canGo(index) && style.navClickable,
              ]}
              onClick={() => moveTo(index)}
            >
              {step.title}
            </div>
            {/* {index < steps.length - 1 && <span css={style.navArrow}><FaAngleRight /></span>} */}
          </React.Fragment>
        ))}
    </div>
  );
}
