import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import DashboardLayoutRoute from 'layout/dashboardLayout';
import DashboardNoAuthLayoutRoute from 'layout/dashboardNoAuthLayout';

import { Login } from 'auth';
import { ChangePassword } from 'site/members/profile/changePassword';
import { ForgotPassword } from 'auth/controls/forgotPassword/forgotPassword';
import { ResetPassword } from 'auth/controls/resetPassword/resetPassword';
import { VerifyEmail } from 'auth/controls/verifyEmail/verifyEmail';
import { CreateAccountLoading } from 'auth/controls/createAccount/createAccount';
import { MenuLoader } from './menuLoader';

// import { Dashboard } from 'site/members/dashboard/dashboard';

const MemberLayout = ({ children }) => (
  <div>
    <MenuLoader />
    <DashboardNoAuthLayoutRoute path='/member/login' component={Login} />
    <DashboardLayoutRoute path='/member/changePassword' component={ChangePassword} />
    <DashboardNoAuthLayoutRoute path='/member/forgotPassword' component={ForgotPassword} />
    <DashboardNoAuthLayoutRoute path='/member/resetPassword' component={ResetPassword} />
    <DashboardNoAuthLayoutRoute path='/member/verifyEmail' component={VerifyEmail} />

    <DashboardNoAuthLayoutRoute path='/member/createAccount' component={CreateAccountLoading} />
    {/* <DashboardLayoutRoute path='/member/dashboard' component={Dashboard} /> */}

    <Route path='/member' exact={true}>
      <Redirect to='/member/dashboard' />
    </Route>
  </div>
);
export default MemberLayout;
