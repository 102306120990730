/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';

import env from 'env';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { FieldError, ServerErrors } from '@site/_helpers';
import { promoStyle } from './promoStyle';
import { fetchPost } from '@lib/net-api/fetch';

const schema = yup.object().shape({
  promoCode: yup.string().required('Promo Code is required.'),
});

export function PromoForm({ cart, applyPromoCode }) {
  const defaultValues = { promoCode: '' };

  const {
    formState: { isSubmitting, errors },
    register,
    handleSubmit,
    reset,
  } = useForm({ defaultValues, resolver: yupResolver(schema) });
  const [serverErrors, setServerErrors] = React.useState([]);

  async function onSubmit(model) {
    try {
      const value = { ...model, cartId: env.getDevCartId() };
      const res = await fetchPost(env.apiBase + '/api/cart/applyPromoCode', value);

      const cart = res.results.cartModel;
      // console.log(cart);
      reset(defaultValues);
      applyPromoCode(cart);
    } catch (e) {
      reset(model);
      setServerErrors(e);
    }
  }

  if (cart.promoInfo && cart.promoInfo.promoCode) return null;
  // console.log(isValid, dirtyFields, isSubmitting, errors);
  return (
    <div className=''>
      <form method='POST' noValidate='' onSubmit={handleSubmit(onSubmit)}>
        <div css={promoStyle.promoSection}>
          <div className='row w-full'>
            <div css={promoStyle.promoCode} className='col-md-6'>
              <div className='form-group'>
                <input
                  id='promoCode'
                  type='text'
                  placeholder='PROMO CODE'
                  className='form-control'
                  {...register('promoCode')}
                />
                <FieldError error={errors?.promoCode} />
              </div>
            </div>
            <div className='col-md-6' style={{ paddingTop: '0.2rem' }}>
              <button type='submit' disabled={isSubmitting} className='btn btn-warning btn-sm'>
                {isSubmitting && (
                  <span className='spinner-grow spinner-grow-sm' role='status' aria-hidden='true'></span>
                )}
                <span className=''>Apply Promo Code</span>
              </button>
            </div>
          </div>
          <ServerErrors errors={serverErrors} />
        </div>
      </form>
    </div>
  );
}
