/** @jsx jsx */
import { jsx } from '@emotion/core';
import style from './orderDetailsStyle';
import React from 'react';

import env from '../../../env';
import { useGet } from '../../../components';
import { Loading, ErrorPopup } from '../../../components';
import { useRouter } from '../../../components';
import utils from '../../../utils';

import { sendPurchaseEvent } from '../gtm';
import { PromoApplied } from './promoApplied';

export function OrderDetails() {
  const get = useGet();
  // const { pathname, query } = useRouter();
  const { query } = useRouter();
  const [pageLoaded, setPageLoaded] = React.useState(false);
  const o = query.o || '';

  React.useEffect(() => {
    get.send(env.apiBase + '/api/order/getPaidOrder/' + o);
    // eslint-disable-next-line
  }, []);

  if (get.loading()) {
    return <Loading />;
  } else if (get.hasErrors()) {
    return <ErrorPopup title='Loading Error' errors={get.errors} />;
  }
  const order = get.response;
  if (!pageLoaded && order) {
    setPageLoaded(true);
    //if (!query.noGTM) gtm.pageView(pathname, 'Order Thankyou');
    if (!query.noGTM) sendPurchaseEvent(order);
    return null;
  }

  return <Details order={order} />;
}

function Details({ order }) {
  console.log('order', order);
  const orderItems = order.orderItems.filter((x) => x.orderItemType === 0);
  const taxLines = order.orderItems.filter((x) => x.orderItemType === 1);

  function itemAmount(orderItem) {
    return (
      (orderItem.unitPrice * 100 +
        (orderItem.addedUnitPrice ? orderItem.addedUnitPrice * 100 : 0) -
        (orderItem.discountUnitPrice ? orderItem.discountUnitPrice * 100 : 0)) /
      100
    );
  }
  // function itemTotalAmount(orderItem) {
  //   return (orderItem.quantity * (orderItem.unitPrice * 100 + orderItem.addedUnitPrice * 100 - orderItem.discountUnitPrice * 100)) / 100;
  // }

  function DiscountAmountDisplay(amount, discountedAmount) {
    return (
      <div>
        <div>
          <b>{utils.money.formatMoney(discountedAmount)}</b>
        </div>
        {amount !== discountedAmount && (
          <div style={{ color: 'red', fontSize: '0.8rem', textDecoration: 'line-through', marginLeft: '1rem' }}>
            {utils.money.formatMoney(amount)}
          </div>
        )}
      </div>
    );
  }

  function sum(array) {
    if (array.length === 0) return 0;
    return array.reduce((p, c) => p + c);
  }
  function DiscountedAmount(type, orderItemId, unitPrice, qty = 1) {
    if (order.promoInfo && order.promoInfo.promoCode) {
      if (type === 'gst') {
        const totalGst = sum(order.promoInfo.promoItems.map((x) => x.discountTaxAmount));
        if (totalGst) {
          return DiscountAmountDisplay(unitPrice, unitPrice - totalGst);
        }
      } else {
        const found = order.promoInfo.promoItems.find((x) => x.orderItemId === orderItemId);
        if (found) {
          return DiscountAmountDisplay(unitPrice, (unitPrice * qty - found.discountItemAmount) / qty);
        }
      }
    }
    return <b>{utils.money.formatMoney(unitPrice)}</b>;
  }

  const billingAddr = order.billingAddress;
  return (
    <div css={style.orderDetails}>
      {!hasGuideInOrder(order) && <style dangerouslySetInnerHTML={{ __html: `.guide-only {display:none}` }}></style>}
      {!hasBookingInOrder(order) && (
        <style dangerouslySetInnerHTML={{ __html: `.booking-only {display:none}` }}></style>
      )}
      <hr />
      <div className='row' style={{ marginLeft: '0px', marginRight: '0px' }}>
        <div className='col-md-12'>
          <div>
            <label css={style.fn}>Order Number:</label> <span css={style.fv}>{order.orderNo}</span>{' '}
          </div>
          <div>
            <label css={style.fn}>Total Amount:</label>{' '}
            <span css={style.fv}>
              <b>AU {utils.money.formatMoney(order.totalAmount, 2)}</b>
            </span>{' '}
          </div>
          <div>
            <label css={style.fn}>Order Date:</label>{' '}
            <span css={style.fv}>{utils.date.formatYYYYMMDD(order.orderDate)}</span>{' '}
          </div>
        </div>
      </div>
      <hr />

      <h3>ITEMS</h3>
      <div css={style.orderItems}>
        {orderItems.map((orderItem, i) => (
          <div css={style.orderItem} className='row' key={orderItem.orderItemId}>
            <div className='col-6'>
              <b dangerouslySetInnerHTML={{ __html: orderItem.itemName }}></b>
              <div dangerouslySetInnerHTML={{ __html: orderItem.itemDescHtml }}></div>
              <ItemExt orderItem={orderItem} />
            </div>
            <div className='col-3'>
              Unit Price: {DiscountedAmount('sku', orderItem.orderItemId, itemAmount(orderItem), orderItem.quantity)}
            </div>
            <div className='col-3'>Qty: {orderItem.quantity}</div>
          </div>
        ))}

        {/* <div css={style.orderItem} className='row'>
          <div className='col-9'>Shipping Fee:</div>
          <div className='col-3'>
            {DiscountedAmount('sku', orderItem.orderItemId, itemAmount(items[index], orderItem))}
          </div>
        </div> */}

        {taxLines.map((orderItem, index) => (
          <div key={orderItem.orderItemId} css={style.orderItem} className='row'>
            <div className='col-9'>Tax: {orderItem.itemName}</div>
            <div className='col-3'>
              {DiscountedAmount(orderItem.itemName === 'GST' ? 'gst' : '', orderItem.orderItemId, orderItem.unitPrice)}
            </div>
          </div>
        ))}

        <PromoApplied order={order} />

        <div className='row' style={{ marginLeft: '0px', marginRight: '0px' }}>
          <div className='col-12'>
            <div css={style.total}>
              Total: AU {utils.money.formatMoney(order.totalAmount, 2)}
              {/* <span className="total-price">Item Total: {utils.money.formatMoney(order.itemAmount)}
                <span> + Shipping Fee: {order.shippingAmount > 0 ? 'AU ' + utils.money.formatMoney(order.shippingAmount) : 'FREE'}</span>
                <span> + GST: {utils.money.formatMoney(order.taxAmount)}</span>
              </span>
              <div className="grand-total">Grand Total (including GST): AU {utils.money.formatMoney(order.totalAmount)}
              </div> */}
            </div>
          </div>
        </div>
      </div>

      <hr />

      {/* <h3>
              DELIVERY METHOD
      </h3>
      <div>
              {order.shippingMethod}
      </div>
      {
        (order.shippingAddress && order.shippingMethod === 'Standard') &&
        <Address addr={order.billingAddress} />
      }
      <Billing billingAddr = {order.billingAddress} useSameAddress = {order.useSameAddress} /> */}

      <h3> BILLING ADDRESS </h3>
      <div>
        <label css={style.fn}>First Name:</label> <span css={style.fv}>{billingAddr.firstName}</span>{' '}
      </div>
      <div>
        <label css={style.fn}>Surname:</label> <span css={style.fv}>{billingAddr.surname}</span>{' '}
      </div>
      <div>
        <label css={style.fn}>Email Address:</label> <span css={style.fv}>{billingAddr.email}</span>{' '}
      </div>
      <div>
        <label css={style.fn}>Phone:</label> <span css={style.fv}>{billingAddr.phone}</span>{' '}
      </div>
    </div>
  );
}

function hasGuideInOrder(order) {
  return order.orderItems.some((x) => x.ext?.itemType === 'guide');
}

function hasBookingInOrder(order) {
  return order.orderItems.some((x) => x.ext?.itemType === 'event');
}

function ItemExt({ orderItem }) {
  // console.log(orderItem.ext);
  const ext = orderItem?.ext;
  if (!ext) return '';
  const desc = ext.itemName;
  if (ext.itemType === 'guide')
    return <div className='text-sm text-green-700' dangerouslySetInnerHTML={{ __html: desc }}></div>;
  else if (ext.itemType === 'event')
    return orderItem.bookingView ? (
      <BookingSummary booking={orderItem.bookingView} ext={ext} />
    ) : (
      <div
        className='text-sm text-green-700'
        dangerouslySetInnerHTML={{ __html: desc.replace('] ', '] <br/> ') }}
      ></div>
    );
  else if (ext.itemName)
    return <div className='text-sm text-green-700' dangerouslySetInnerHTML={{ __html: desc }}></div>;

  return '';
}

function BookingSummary({ ext, booking }) {
  return (
    <div className='text-sm'>
      {/* <div className='text-green-700'>[{booking.startDateTimeText}]</div> */}
      {/* <div className='text-green-700'>{booking.eventTitle}</div> */}
      <div className='text-green-700' dangerouslySetInnerHTML={{ __html: ext.itemName }}></div>
      <div className='text-red-500 font-bold'>Booking ID: {booking.bookingCode}</div>
    </div>
  );
}
