/** @jsx jsx */
import { jsx } from '@emotion/core';
import { SiteLink } from 'components';
import { getTileStyle } from './pageTileStyle';
import { getAESTDate } from 'site/_helpers/dateTime';
import utils from 'utils';

export function EventTile({ event, useSlider, disableLink }) {
  // console.log(event);
  const tileLink = `/event/${event.eventSlug}`;
  const title = event.eventTitle;
  const date = getAESTDate(event.startDateTime, 'MMMM d');
  const duration = event.duration;
  const desc = event.shortDesc || '';

  const fullyBooked = event.numOfPlaces && (event.usedNumOfPlaces || 0) >= event.numOfPlaces;

  const s = getTileStyle();
  return (
    <SiteLink to={tileLink} css={[s.tile, useSlider && s.tileSlider]} className='relative'>
      {fullyBooked && (
        <div className='absolute right-1 top-1 bg-gray-600 text-white py-0 px-2 text-xs rounded-sm'>FULLY BOOKED</div>
      )}
      <div className='tileContent' css={s.tileContent}>
        <div css={s.datetime}>
          {date} <span css={s.duration}>({utils.site.toHoursText(duration)})</span>
        </div>
        <div css={s.title}>{title}</div>
        <div css={s.desc}>{desc}</div>
      </div>
    </SiteLink>
  );
}
