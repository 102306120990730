/** @jsx jsx */
import { jsx } from '@emotion/core'
import utils from 'utils';
import { SiteLink } from 'components';
import { getImageBannerStyle } from './singlePanelStyle';

export function SingleBannerPanel({ bgUrl, heightSet, photoCredit, photoCreditUrl }) {
  const cssClass = utils.classNames(`single-banner`, `banner-item`);
  const s = getImageBannerStyle(heightSet);

  return (
    <div className={cssClass} data-pxh={heightSet} css={s.bannerHeight}
      style={{ backgroundImage: utils.css.bgUrlStyle(bgUrl) }}>
      {(photoCredit && !photoCreditUrl) && <div css={s.photoCredit}>{photoCredit}</div>}
      {(photoCredit && photoCreditUrl) && <SiteLink to={photoCreditUrl} css={s.photoCredit}>{photoCredit}</SiteLink>}
    </div>
  );
}
