import React from 'react';
import env from 'env';
import utils from 'utils';
import { useGet } from 'components';
// import { SiteLink } from 'components';
import { Loading, ErrorPopup } from 'components';
import { useRouter } from 'components';
import { SingleBannerPanel } from 'site/singleBanner/singlePanel';
import { EventDetails } from './eventDetails';

const r = utils.site.resourcePath;
const bannerImage = '/sites/preventingharm/media/bwbanners/adobestock_392700489.jpg';

export function EventView() {
  const { query } = useRouter();
  const get = useGet();

  const slug = query.slug || '';

  React.useEffect(() => {
    console.log('getting...');
    get.send(env.apiBase + `/api/event/getEvent/${slug}`);
    // eslint-disable-next-line
  }, [slug]);

  if (get.loading()) {
    return <Loading />;
  } else if (get.hasErrors()) {
    return <ErrorPopup title='Loading Error' errors={get.errors} />;
  }

  const event = get.response;

  return (
    <>
      <SingleBannerPanel heightSet={'thin'} bgUrl={r(bannerImage)} />

      <div className='container' style={{ paddingBottom: '6rem' }}>
        <EventDetails event={event} />
      </div>
    </>
  );
}
