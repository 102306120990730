import React from 'react';
import parse, { domToReact, attributesToProps } from 'html-react-parser';
import utils from '../../../../utils';
import env from '../../../../env';

//https://github.com/remarkablemark/html-react-parser

const options = {
  replace,
};
let _embedDataList = [];
let _cmsOption = {};

export function renderHtml(html, cmsOption) {
  _cmsOption = cmsOption;
  if (!cmsOption.isCmsEdit || cmsOption.cmsViewMode === 'viewonly') {
    _embedDataList = cmsOption?.pageData?.embedDataList;
    return parse(html || '', options);
  } else {
    return parse(html || '', {});
  }
}

function replace(node) {
  //console.log(node)
  if (!env.isProd && node.type === 'tag' && node.name === 'img') {
    return replaceImg(node);
  } else if (node.type === 'tag' && node.attribs.class === 'button') {
    return replaceButton(node);
  } else if (node.type === 'tag' && node.attribs['data-embed-jaba']) {
    return replaceEmbedCode(node);
  }
}

function replaceImg(node) {
  if (node.attribs && node.attribs.src) {
    const props = attributesToProps(node.attribs);
    props.src = utils.site.resourcePath(node.attribs.src);
    // eslint-disable-next-line
    return <img {...props} />;
  }
  return null;
}

function replaceButton(node) {
  if (node.children && node.children.some((x) => x.name === 'span')) return null;
  const props = attributesToProps(node.attribs);
  return React.createElement(node.name, props, <span>{domToReact(node.children, options)}</span>);
}

function replaceEmbedCode(node) {
  const id = node.attribs['data-embed-jaba'];
  const found = utils.array.find(_embedDataList, (x) => x.id === id);
  if (found) {
    //console.log(found)
    return <div className={`codeType_${found.codeType}`}>{renderHtml(found.code, _cmsOption)}</div>;
  } else return null;
}
