/** @jsx jsx */
import { jsx } from '@emotion/core';
import utils from '../../../utils';
import { SiteLink } from '../../../components';
import { tileStyle } from './productTileStyle';

export function ProductTile({ product }) {
  const s = tileStyle;
  const imageUrl = product.thumbnailUrl ? utils.site.resourcePath(product.thumbnailUrl) : '/assets/product-empty.png';
  //console.log("product : ", product)
  const productUrl = product.productUrl ? product.productUrl : `/product/${product.productSlug}`;
  return (
    <div css={[s.tile]}>
      <SiteLink css={s.tileLink} to={productUrl}>
        <div css={s.prdImage}>
          <div css={s.bg} style={{ backgroundImage: utils.css.bgUrlStyle(imageUrl) }}></div>
        </div>
        <div css={s.shortDes}>{product.productName}</div>
        <div css={s.name}>
          {product.shortDesc}
          {/* <div className="productTile__inlineRating">
            <div data-bv-show="inline_rating" data-bv-product-id={product.productCode}></div>
          </div> */}
        </div>

        <div css={s.price}>
          {product.productStatus !== 0
            ? product.productStatusText
            : product.priceTextOveriding
            ? product.priceTextOveriding
            : 'AU' + utils.money.formatMoney(product.priceIncTax)}
        </div>
      </SiteLink>
    </div>
  );
}
