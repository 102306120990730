/** @jsx jsx */
import { jsx } from '@emotion/core';
// import cmsUtils from '../../cmsUtils';
import utils from 'utils';
import { DashboardLoading } from 'site/members/dashboard/dashboardLoading';

export function MemberDashboardV2(props) {
  const item = props.item;
  const cmsOption = props.cmsOption || {};
  // console.log(cmsOption.pageData.pageUrl, cmsOption);
  const isCmsEdit = !!cmsOption.isCmsEdit;
  const pageUrl = cmsOption.pageData.pageUrl;
  // console.log('item', item, isCmsEdit);

  const cssClass = utils.classNames('cms_item', item.cssClass || item.anchorName || '');

  return isCmsEdit ? (
    <div className={cssClass} data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName}>
      <div className='m-4 p-2 border bg-green-50'>
        Member Dashboard (This will list cateogry/guide tiles automatically)
      </div>
    </div>
  ) : (
    <div className={cssClass} id={item.anchorName}>
      <DashboardLoading pageUrl={pageUrl} version = 'v2' />
    </div>
  );
}
