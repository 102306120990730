import React from 'react';
import { Route } from 'react-router-dom';

import { Loading } from 'components';
import { useRouter } from '../components';
import { useAuth } from 'auth';
import { MenuLoader } from './menuLoader';

const DashboardLayout = ({ children, ...rest }) => {
  const isCancelled = React.useRef(false);
  const [sessionChecked, setSessionChecked] = React.useState(false);
  const { navigate } = useRouter();
  const auth = useAuth();

  React.useEffect(() => {
    const checkAuth = async () => {
      if (!isCancelled.current) {
        const loggedIn = await auth.loggedIn();
        // console.log({ loggedIn })
        if (!loggedIn) {
          navigate('/member/login');
        } else {
          setSessionChecked(true);
        }
      }
    };
    checkAuth();
    return () => {
      isCancelled.current = true;
    };
  }, [auth, navigate]);

  if (!sessionChecked) {
    return (
      <div>
        <Loading />
      </div>
    );
  } else {
    return <div>{children}</div>;
  }
};

const DashboardLayoutRoute = ({ component: RenderComponent, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <DashboardLayout>
          <MenuLoader />
          <RenderComponent {...matchProps} />
        </DashboardLayout>
      )}
    />
  );
};

export default DashboardLayoutRoute;
