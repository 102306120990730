/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import utils from 'utils';
import { SiteLink } from 'components';
import { getTileStyle } from './pageTileStyle';
import { Popup } from 'site/_popup/popup';
// import { ErrorPopup } from 'components';
import { AddToCart } from './addToCart';
import { renderHtml } from './renderHtml';

export function ProductTile({ product, useSlider }) {
  const [showPopup, setShowPopup] = React.useState(false);
  // console.log(product);
  const tileLink = '/products' + product.pageUrl;
  const imageUrl = utils.site.resourcePath(product.tileImageUrl);
  const title = product.guideTitle || product.tileTitle;
  const desc = product.tileDesc;
  const price = utils.money.formatMoney(product.price);

  function addToCart(e) {
    e.preventDefault();
    e.stopPropagation();
    console.log('addToCart');
  }

  function viewDetails(e) {
    e.preventDefault();
    e.stopPropagation();
    setShowPopup(true);
  }
  const s = getTileStyle();
  return (
    <SiteLink onClick={viewDetails} to={tileLink} css={[s.tile, useSlider && s.tileSlider]}>
      <div className='tileBg' css={s.tileBg} style={{ backgroundImage: utils.css.bgUrlStyle(imageUrl) }}></div>
      <div className='tileContent' css={s.tileContent}>
        <div css={s.title}>{title}</div>
        <div css={s.desc}>{desc}</div>
      </div>
      <div className='tileEcm flex items-center justify-between' css={s.tileEcm}>
        <div css={s.price} className='font-bold'>
          {price}
        </div>
        <AddToCart product={product} />
      </div>

      <ProductPopup
        showPopup={showPopup}
        onClosed={(e) => {
          e.stopPropagation();
          setShowPopup(false);
        }}
        addToCart={addToCart}
        product={product}
      />
    </SiteLink>
  );
}

function ProductPopup({ showPopup, onClosed, product, addToCart }) {
  const title = product.guideTitle || product.tileTitle;
  const image = product.tileImageUrl && utils.site.resourcePath(product.tileImageUrl);
  const desc = product.ecmDescription || product.tileDesc;
  const price = utils.money.formatMoney(product.price);

  const guide = {
    lineHeight: '1.3',
    textAlign: 'center',
  };
  return (
    <Popup showPopup={showPopup} onClosed={onClosed}>
      <div css={guide}>
        <h3 className='font-bold'>{title}</h3>

        <div className='my-4'>
          {image && (
            <div
              className=''
              style={{
                width: '100%',
                height: '0',
                paddingTop: '50%',
                overflow: 'hidden',
                position: 'relative',
              }}
            >
              <img
                className='max-w-full'
                src={image}
                alt={title}
                style={{
                  position: 'absolute',
                  objectFit: 'cover',
                  top: '0',
                  left: '0',
                  right: '0',
                  bottom: '0',
                }}
              />
            </div>
          )}
          <div className='text-left my-4'>
            <div className='md:ml-4'>{renderHtml(desc)}</div>
          </div>
        </div>

        <div className='tileEcm md:flex items-center justify-center mt-8'>
          <div className='font-bold mx-4 mb-2'>{price}</div>

          <AddToCart product={product} />
          <button className='btn btn-link btn-sm ml-2' onClick={onClosed}>
            CLOSE
          </button>
        </div>
      </div>
    </Popup>
  );
}
