/** @jsx jsx */
import { jsx } from '@emotion/core'
// import React from 'react';
import { getMonthlyOrYearlyStyle } from './monthlyOrYearlyStyle';
import { FaCheckCircle } from 'react-icons/fa'
import { priceData } from './_priceData'

export function MonthlyOrYearly({ subsType, per, setPer }) {

  const s = getMonthlyOrYearlyStyle()

  return <div style={{ marginBottom: 30 }}>
    <div css={s.container}>
      <div css={[s.option, per === 'monthly' && s.optionSelected]} onClick={() => setPer('monthly')}>
        {per === 'monthly' && <FaCheckCircle css={s.icon} />}
        <h3 css={s.header}>Monthly</h3>
        <div css={s.priceBlock}>
          <span css={s.price}>AUD {subsType.id === 'standard' ? priceData.standard.monthly : priceData.pro.monthly}</span>
          <span css={s.per}>per month</span>
        </div>
      </div>
      <div css={[s.option, per === 'yearly' && s.optionSelected]} onClick={() => setPer('yearly')}>
        {per === 'yearly' && <FaCheckCircle css={s.icon} />}
        <h3 css={s.header}>Yearly</h3>
        <div css={s.priceBlock}>
          <span css={s.price}>AUD {subsType.id === 'standard' ? priceData.standard.yearly : priceData.pro.yearly}</span>
          <span css={s.per}>per month</span>
        </div>
      </div>
    </div>
  </div >
}