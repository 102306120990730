import { variables } from 'cssInJs';
import { lighten } from 'polished';

const primaryColor = variables.primaryColor;
console.log(primaryColor);
export const loginStatusStyle = {
  panel: {
    padding: 30,
    background: lighten(0.5, primaryColor),
    textAlign: 'center',
    '.btn': {
      minWidth: 100,
    },
  },
};
