import React from 'react';
import env from 'env';
import { Loading, ErrorPopup } from 'components';
import { useRouter } from 'components';

import { usePost } from 'components';
import { Pay } from '../signupSteps/cartPayment/pay';

export function VerifyEmail() {
  const { query } = useRouter();
  const token = query.u || '';

  const post = usePost();

  React.useEffect(() => {
    post.send(env.apiBase + "/api/subscription/VerifyEmail", { token: token });
    // eslint-disable-next-line
  }, []);

  if (post.loading()) {
    return <Loading><div style={{ marginTop: '2rem', textAlign: 'center' }}>Verifying your email...</div></Loading>
  } else if (post.hasErrors()) {
    return <ErrorPopup title="Loading Error" errors={post.errors} />
  }

  const data = post.response;
  const payVM = data.results.payVM;
  console.log(data.results.payVM)
  return <div className="container" style={{maxWidth: 800, paddingBottom: '6rem'}}>
    <p style={{margin: '2rem 0'}}>Your email is successfully verified.</p>
    <h3>Payment</h3>

    <Pay payVM={payVM} />
  </div>
}
