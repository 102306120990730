import variables from './cssInJs/variables';
import { getIdToken } from 'lib/auth/services/authHelper';

const cssEmotion = {
  breakpointNames: variables.breakpointNames,
  breakpoints: variables.breakpoints,
};

const firebaseSettings = {
  apiKey: 'AIzaSyCDO7aDorNJiJqoNZqi53EEAKDDCXGrl4Q',
  authDomain: 'preventingharm-a588b.firebaseapp.com',
  // databaseURL: "https://jaba-pwa.firebaseio.com",
  projectId: 'preventingharm-a588b',
  storageBucket: 'preventingharm-a588b.appspot.com',
  messagingSenderId: '176116373420',
  appId: '1:176116373420:web:72fb7d1814158d73f28c09',
};

const apiNetJwt = async (options) => {
  const token = await getIdToken();
  //console.log(token)
  if (token) {
    options.headers = options.headers || {};
    options.headers['Authorization'] = `Bearer ${token}`;
  }
};
export default {
  cssEmotion,
  firebaseSettings,
  apiNetJwt,
};
