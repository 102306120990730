/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import utils from 'utils';
import { TileList } from 'site/tileList/tileList';
import { getTileListStyle } from 'site/tileList/tileListStyle';

import { PageTile } from 'site/_cmsItems/pageTiles/view/pageTile/pageTile';

export function CategoryTiles({ categories }) {
  
  console.log("ddsfsddsf", categories);
  
  
  const pages = React.useMemo(() => getPages(categories), [categories]);
  console.log(pages);
  
  if (categories.length === 0) return <div>No available guides.</div>;

  const tiles = pages.map((page, index) => <PageTile key={index} page={page} useSlider={false} disableLink={false} />);
  return <TileList tiles={tiles} style={getTileListStyle()} useSlider={false} />;
}

function getPages(categories) {
  return (categories || []).map((x) => ({
    pageTitle: x.categoryName || x.tileTitle || x.pageTitle,
    tileDesc: x.tileDesc,
    pageUrl: utils.site.getCategoryShopUrl(x.pageUrl),
    imageUrl: x.tileImageUrl,
  }));
}
