/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import { TileList } from 'site/tileList/tileList';
import { getTileListStyle } from 'site/tileList/tileListStyle';

import { PageTile } from 'site/_cmsItems/pageTiles/view/pageTile/pageTile';

export function GuideTiles({ guides }) {
  const pages = React.useMemo(() => getPages(guides), [guides]);

  if (guides.length === 0) return <div>No available guides.</div>;

  const tiles = pages.map((page, index) => <PageTile key={index} page={page} useSlider={false} disableLink={false} />);
  return <TileList tiles={tiles} style={getTileListStyle()} useSlider={false} />;
}

function getPages(guides) {
  return (guides || []).map((x) => ({
    pageTitle: x.guideTitle || x.tileTitle || x.pageTitle,
    tileDesc: x.tileDesc,
    pageUrl: x.pageUrl,
    imageUrl: x.tileImageUrl,
  }));
}
