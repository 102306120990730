/** @jsx jsx */
import { jsx } from '@emotion/core';
import { mq } from 'cssInJs';
import React from 'react';
import { TileList } from 'site/tileList/tileList';
import { getTileListStyle } from 'site/tileList/tileListStyle';

import { EventTile } from './tile/eventTile';
import { getAESTMonth } from 'site/_helpers/dateTime';

export function EventTiles({ events }) {
  const months = React.useMemo(() => getMonths(events), [events]);

  if (months.length === 0) return <div>No events.</div>;
  return (
    <div>
      {months.map((month) => (
        <div key={month.id}>
          <h3>{month.title}</h3>
          <MonthEventTiles events={month.events} />
        </div>
      ))}
    </div>
  );
}

function MonthEventTiles({ events }) {
  if (events.length === 0) return <div>No events.</div>;

  const overridingStyle = {
    holder: mq({
      marginTop: [5, null, 5],
    }),
  };

  const tiles = events.map((event, index) => (
    <EventTile key={index} event={event} useSlider={false} disableLink={false} />
  ));
  return <TileList tiles={tiles} style={getTileListStyle()} overridingStyle={overridingStyle} useSlider={false} />;
}

function getMonths(events) {
  const months = events.reduce((acc, event) => {
    const month = getAESTMonth(event.startDateTime);
    const key = month.id;
    (acc[key] = acc[key] || { id: month.id, title: month.title, events: [] }).events.push(event);
    return acc;
  }, {});
  const sorted = Object.keys(months)
    .sort()
    .map((key) => ({ ...months[key] }));
  // console.log(months, sorted);

  return sorted;
}
