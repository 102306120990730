import React from 'react';
import utils from 'utils';
import { Popup } from 'site/_popup/popup';
import { RegisterForm } from './registerForm';

export function RegisterPopup({ showPopup, onClosed, event }) {
  const title = event.eventTitle;

  return (
    <Popup showPopup={showPopup} onClosed={onClosed}>
      <div className='mx-2'>
        <h3 className='font-bold'>
          {title}
          {event.price != null && (
            <span className='ml-2'> - {(event.price || 0) === 0 ? 'FREE' : utils.money.formatMoney(event.price)}</span>
          )}
        </h3>
        <p className='mt-6'>Please fill out the following registration form.</p>

        <div className='my-6'>
          <RegisterForm event={event} onClosed={onClosed} />
        </div>
      </div>
    </Popup>
  );
}
