// import { css } from '@emotion/core'
import { variables, mq } from 'cssInJs'
import { rgba } from 'polished'

export function getMonthlyOrYearlyStyle() {
  return {
    container: mq({
      maxWidth: 800,
      display: 'flex',
      marginLeft: -20,
      marginRight: -20,
    }),
    option: mq({
      position: 'relative',
      margin: 20,
      paddingLeft: 40,
      paddingRight: 40,
      paddingTop: 20,
      paddingBottom: 20,
      flex: '0 0 50%',
      background: '#f3f3f3',
      transition: 'all 0.3s',
      border: '3px solid transparent',
      '&:hover, &:active': {
        //color: 'white',
        background: rgba(variables.primaryColor, 0.1),
        border: `3px solid ${variables.primaryColor}`
      },
      'ul': {
        margin: '1rem 0',
        paddingLeft: 20
      }
    }),
    optionSelected: mq({
      border: `3px solid ${variables.primaryColor}`
    }),
    icon: mq({
      position: 'absolute',
      top: 5,
      right: 5,
      fontSize: '2rem',
      color: variables.primaryColor,
    }),
    header: mq({
      textAlign: 'center',
    }),
    priceBlock: mq({
      marginTop: 10,
      marginBottom: 10
    }),
    price: mq({
      fontSize: '1.6rem',
      fontWeight: 'bold',
      color: variables.primaryColor
    }),
    per: mq({
      fontSize: '0.875rem',
      color: '#999',
      marginLeft: '1rem'
    }),
  }
}
