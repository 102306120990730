import React from 'react';
import { useAuth } from 'auth';
import { SiteLink } from 'components';

export function NoPermission(props) {
  const auth = useAuth();
  const debugInfo = props.debugInfo;

  function goBack(e) {
    e.preventDefault();
    if (typeof window !== `undefined`) {
      window.history.back();
    }
  }

  const loggedIn = !!auth.user;

  return (
    <div className='error-panel'>
      <div className='container'>
        <h1>No Permission</h1>
        {!loggedIn && (
          <div className='text-red-600'>
            <p>
              To access this page, please <SiteLink to='/member/login'>Log in</SiteLink> first.
            </p>
          </div>
        )}

        {loggedIn && (
          <div className='text-red-600'>
            <p>Sorry, you don't have a permission to this page.</p>
          </div>
        )}

        <div>
          <button onClick={(e) => goBack(e)}>or Go Back</button>
        </div>

        {debugInfo && (
          <pre style={{ marginTop: '3rem', color: 'chocolate', fontSize: '0.7rem' }}>
            Error message: {debugInfo.pageUrl} - {debugInfo.loadFrom}{' '}
          </pre>
        )}
      </div>
    </div>
  );
}
