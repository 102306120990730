/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import { TileList } from 'site/tileList/tileList';
import { getTileListStyle } from 'site/tileList/tileListStyle';

import { ProductTile } from './productTile/productTile';

export function GuideTiles({ guides, categories }) {
  const products = React.useMemo(() => getProducts(guides, categories), [guides, categories]);
  const tiles = products.map((product, index) => (
    <ProductTile key={index} product={product} useSlider={false} disableLink={false} />
  ));

  return (
    <>
      <TileList tiles={tiles} style={getTileListStyle()} useSlider={false} />
    </>
  );
}

function getProducts(guides, categories) {
  console.log(guides, categories);
  return (guides || []).map((x) => ({
    ...x,
  }));
}
