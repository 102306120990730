const overloading = {
  breakpointNames: ['sm', 'md', 'lg', 'xl', 'xxl'],
  breakpoints: [576, 768, 992, 1200, 1600],
};

const variables = {
  familyBase: '"proxima-nova",sans-serif',
  familyHeader: '"neo-sans",serif',

  primaryColor: '#0e76bc',
  fontColor: '#413e3d',
  bgColor: '#ebebeb',
  blueColor: '#0e76bc',
  darkGray: '#413e3d',
  greenColor: '#8dc63f',

  ...overloading,
};

export default variables;
