import React, { useState, useEffect } from 'react';
import { SiteLink } from '../../components';
import { useStore } from '../../store';

import { FaChevronDown } from 'react-icons/fa';

import { MemberMenu } from 'layout/memberMenu';
import { CartLoader } from 'layout/cartLoader';
import { SearchBar } from './searchBar';

export function NavBar(props) {
  const open = props.open;
  //const currentLocation = props.currentLocation;
  const { state } = useStore();

  const menu = state.menu || { items: [] };
  const currentLocation = state.history && state.history.length > 0 ? state.history[state.history.length - 1].path : '';
  const depth = 1;
  const menuItemElts = menu.items.map((item, index) => (
    <MenuItem
      item={item}
      key={index}
      depth={depth}
      onCloseMenu={props.onCloseMenu}
      currentLocation={currentLocation}
    ></MenuItem>
  ));
  return (
    <div className={`navBar ${open ? 'navBar--on' : ''}`} id='sliding-menu'>
      <SearchBar onCloseMenu={props.onCloseMenu}/>
      <div className='navBar__scrollArea' id='top-menu'>
        <nav className='navBar_nav'>
          <MemberMenu onCloseMenu={props.onCloseMenu} />
          <ul>{menuItemElts}</ul>
          <Cart onCloseMenu={props.onCloseMenu} state={state} />
        </nav>
      </div>
      <CartLoader />
    </div>
  );
}

function MenuItem(props) {
  const [subMenuOpened, setSubMenuOpened] = useState(false);
  const item = props.item;
  const subItems = item.items || [];
  const currentLocation = props.currentLocation;

  const subItemsElts =
    subItems.length > 0
      ? subItems.map((subItem, index) => <SubMenuItem item={subItem} key={index} onCloseMenu={props.onCloseMenu} />)
      : null;

  useEffect(() => {
    //console.log('navbar', currentLocation);
    setSubMenuOpened(false);
  }, [currentLocation]);

  function closeMenu() {
    props.onCloseMenu();
  }

  function toggleSubmenu(e) {
    e.preventDefault();
    e.stopPropagation();
    setSubMenuOpened(!subMenuOpened);
  }

  return (
    <li className={`navBar__item ${subMenuOpened ? 'navBar__item--open' : ''}`}>
      <SiteLink className='navBar__link' to={item.url} onClick={() => closeMenu()}>
        <span>{item.title}</span>
        {subItemsElts && (
          <span className='navBar__icon' onClick={(e) => toggleSubmenu(e)}>
            <FaChevronDown />
          </span>
        )}
      </SiteLink>
      {subItemsElts && <ul className={`navBar__subMenu`}>{subItemsElts}</ul>}
    </li>
  );
}

function SubMenuItem(props) {
  const item = props.item;
  function closeMenu() {
    props.onCloseMenu();
  }

  return (
    <li className='navBar__subItem'>
      <SiteLink className='navBar__subLink' to={item.url} onClick={() => closeMenu()}>
        <span>{item.title}</span>
      </SiteLink>
    </li>
  );
}

function Cart({ onCloseMenu, state }) {
  const cartItemNo = state?.cartInfo?.numberOfItems || 0;
  return (
    <ul>
      <li className={`navBar__item`}>
        <SiteLink
          className='navBar__link'
          to='/cart'
          onClick={onCloseMenu}
          style={{ background: cartItemNo > 0 ? '#ea8b2a' : '#2ab5ea' }}
        >
          <span>CART</span>
          <span>({cartItemNo})</span>
        </SiteLink>
      </li>
    </ul>
  );
}
