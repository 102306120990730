/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import env from 'env';
import { CategoryTiles } from 'site/ecm/category/categoryTiles';
import { useGet } from 'components';
import { Loading, ErrorPopup } from 'components';

export function CategoryTilesLoading({type}) {
  const get = useGet();

  React.useEffect(() => {
    get.send(`${env.apiBase}/api/guide/getCategories?type=${type}`);
    // eslint-disable-next-line
  }, []);

  if (get.loading()) {
    return <Loading />;
  } else if (get.hasErrors()) {
    return <ErrorPopup title='Loading Error' errors={get.errors} />;
  }

  const data = get.response;
  
  return (
    <div>
      <CategoryTiles categories={data.categories} />
    </div>
  );
}
