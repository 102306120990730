import { variables, mq } from 'cssInJs';
import { settings as defaultSettings } from 'site/tileList/config';

//import { rgba } from 'polished';

export function getTileStyle(settings = defaultSettings) {
  const numOfTilesPerBreakpoints = settings.numOfTilesPerBreakpoints
  const marginLR = settings.marginLR
  const marginTB = settings.marginTB

  return {
    tile: mq({
      paddingLeft: marginLR.map(x => x === null ? null : x / 2),
      paddingRight: marginLR.map(x => x === null ? null : x / 2),
      paddingTop: marginTB.map(x => x === null ? null : x / 2),
      paddingBottom: marginTB.map(x => x === null ? null : x / 2),
      maxWidth: numOfTilesPerBreakpoints.map(x => x === null ? null : 100 / x + '%'),
      flexBasis: numOfTilesPerBreakpoints.map(x => x === null ? null : 100 / x + '%'),
      flexGrow: 0,
      flexShrink: 0,
      textAlign: 'left',
      display: 'flex',
      flexDirection: 'column',
      color: 'inherit',
      ':hover': {
        textDecoration: 'none',
        color: 'inherit',
        '.tileBg': {
          transform: 'scale(1.02)',
          transition: 'all .2s ease-in-out'
        },
        '.tileContent': {
          backgroundColor: variables.greenColor
        }
      }
    }),
    tileSlider: mq({
      maxWidth: '100% !important'
    }),
    tileBg: mq({
      backgroundColor: 'white',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      height: 0,
      paddingBottom: '56.25%',
    }),
    tileContent: mq({
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      lineHeight: 1.3,
      //boxShadow: '4px 4px 14px 4px rgba(190,190,190,0.5)',
      backgroundColor: variables.blueColor,
      marginTop: [10],
      padding: [20],
      transition: 'all .2s ease-in-out',
      color:'white'
    }),
    title: mq({
      //fontFamily: variables.familyHeader,
      fontSize: '1.3rem',
      textTransform: 'uppercase',
      lineHeight: 1.3,
      //fontWeight: 900,
      //textAlign: 'center',
      fontWeight:700
    }),
    desc: mq({
      marginTop: 5,
      fontSize: '0.9rem',
      display: '-webkit-box',
      WebkitLineClamp: '9',
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden',
      fontWeight:400
    }),
  }
}

export function getTileListOverridingStyle() {
  return {
    tiles: mq({
      alignItems: 'flex-start',
    })
  }
}