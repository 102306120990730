/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';

import env from 'env';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { ServerErrors } from '@site/_helpers';
import { promoStyle } from './promoStyle';
import { fetchPost } from '@lib/net-api/fetch';

const schema = yup.object().shape({});

export function PromoApplied({ cart, applyPromoCode }) {
  const defaultValues = {};

  const {
    formState: { isSubmitting },
    handleSubmit,
    reset,
  } = useForm({ defaultValues, resolver: yupResolver(schema) });
  const [serverErrors, setServerErrors] = React.useState([]);

  async function onSubmit(model) {
    try {
      const value = { ...model, cartId: env.getDevCartId() };
      const res = await fetchPost(env.apiBase + '/api/cart/removePromoCode', value);

      const cart = res.results.cartModel;
      // console.log(cart);
      reset(defaultValues);
      applyPromoCode(cart);
    } catch (e) {
      reset(model);
      setServerErrors(e);
    }
  }

  if (!(cart.promoInfo && cart.promoInfo.promoCode)) return null;

  // console.log(isValid, dirtyFields, isSubmitting, errors);
  return (
    <div className=''>
      <form method='POST' noValidate='' onSubmit={handleSubmit(onSubmit)}>
        <div css={promoStyle.promoSection}>
          <div className='row w-full'>
            <div css={promoStyle.promoCode} className='col-md-9'>
              Promo <b>{cart.promoInfo.promoCode}</b>({cart.promoInfo.promoDesc}) has been applied.
            </div>
            <div className='col-md-3'>
              <button type='submit' disabled={isSubmitting} className='btn btn-warning btn-sm'>
                {isSubmitting && (
                  <span className='spinner-grow spinner-grow-sm' role='status' aria-hidden='true'></span>
                )}
                <span className=''>Remove Promo</span>
              </button>
            </div>
          </div>
          <ServerErrors errors={serverErrors} />
        </div>
      </form>
    </div>
  );
}
