import { css } from '@emotion/core';
import { mq } from 'cssInJs';

const navs = {
  navs: css(
    mq({
      marginTop: [5, 10, 20],
      marginBottom: 15,
      display: 'flex',
      justifyContent: 'center',
      borderBottom: 'solid 10px #eee',
    })
  ),
  nav: css(
    mq({
      // width: 20,
      // height: 30,
      background: '#ccc',
      margin: 5,
      padding: '0.5rem 1rem',
      transform: 'skewX(-12deg)',
    })
  ),
  navCurrent: css({
    backgroundColor: 'orange',
    color: 'white',
    fontWeight: 'bold',
  }),
  navFilled: css({
    backgroundColor: '#666',
    color: '#ccc',
  }),
  navClickable: css({
    cursor: 'pointer',
  }),
};

export default {
  arrow: css(
    mq({
      position: 'absolute',
      cursor: 'pointer',
      color: '#aaa',
      transition: 'all 0.3s',
      zIndex: '1',
      // xs, sm(>=576), md(>=768), lg(>=992), xl(>=1200), xxl(>=1600)
      top: 0,
      // [mqMax.md]: {
      //   width: 100
      // },
      width: [20, 25, 30, 40],
      '&:hover': {
        color: '#666',
      },
    })
  ),
  arrowPrev: css(
    mq({
      left: [-15, 0],
    })
  ),
  arrowNext: css(
    mq({
      right: [-15, 0],
    })
  ),

  ...navs,
};
