/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import env from 'env';
import { EventTiles } from '@site/event/eventList/eventTiles';
import { useGet } from 'components';
import { Loading, ErrorPopup } from 'components';

export function EventTilesLoading() {
  const get = useGet();

  React.useEffect(() => {
    get.send(`${env.apiBase}/api/event/getEvents`);
    // eslint-disable-next-line
  }, []);

  if (get.loading()) {
    return <Loading />;
  } else if (get.hasErrors()) {
    return <ErrorPopup title='Loading Error' errors={get.errors} />;
  }

  const data = get.response;
  return (
    <div>
      <EventTiles events={data.events} />
    </div>
  );
}
