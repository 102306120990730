/** @jsx jsx */
import { jsx } from '@emotion/core';
import style from './orderItemStyle';
import React from 'react';

import { Button, ErrorMessage } from '../../../components';
import utils from '../../../utils';

import { CartSummary } from '../cartSummary/summary';
import { PromoForm } from './promoCodeForm';
import { PromoApplied } from './promoApplied';

export function OrderItems({ cart, form, moveNext, post, remove, applyPromoCode }) {
  //const model = form.model;
  const items = form.findControl('items').controls;
  // const render = (name, parent = null) => form.renderControl(name, parent);

  function itemAmount(item, orderItem) {
    const val = item.getValue();
    return (val.quantity * (orderItem.unitPrice * 100)) / 100;
  }

  const next = (e) => {
    form.validateForm(e, () => {
      moveNext();
    });
  };

  function ItemVariantSummary({ orderItem }) {
    if (!orderItem.variantOptionsSerialised) return null;
    const options = JSON.parse(orderItem.variantOptionsSerialised);
    return (
      <div>
        {options
          .map((x) => (
            <span>
              <span css={style.optionName}>{x.Name}:</span>
              <span css={style.optionValue}>{x.Value}</span>
            </span>
          ))
          .reduce(
            (acc, x) =>
              acc === null ? (
                x
              ) : (
                <React.Fragment>
                  {acc}, {x}
                </React.Fragment>
              ),
            null
          )}
      </div>
    );
  }

  function sum(array) {
    if (array.length === 0) return 0;
    return array.reduce((p, c) => p + c);
  }

  function DiscountAmountDisplay(amount, discountedAmount) {
    return (
      <div>
        <div>
          <b>{utils.money.formatMoney(discountedAmount)}</b>
        </div>
        <div style={{ color: 'red', fontSize: '0.8rem', textDecoration: 'line-through', marginLeft: '1rem' }}>
          {utils.money.formatMoney(amount)}
        </div>
      </div>
    );
  }
  function DiscountedAmount(type, orderItemId, amount) {
    if (cart.promoInfo && cart.promoInfo.promoCode) {
      if (type === 'gst') {
        const totalGst = sum(cart.promoInfo.promoItems.map((x) => x.discountTaxAmount));
        if (totalGst) {
          return DiscountAmountDisplay(amount, amount - totalGst);
        }
      } else {
        const found = cart.promoInfo.promoItems.find((x) => x.orderItemId === orderItemId);
        if (found) {
          return DiscountAmountDisplay(amount, amount - found.discountItemAmount);
        }
      }
    }
    return <b>{utils.money.formatMoney(amount)}</b>;
  }

  const orderItems = cart.orderItems.filter((x) => x.orderItemType === 0);
  const taxLines = cart.orderItems.filter((x) => x.orderItemType === 1);

  return (
    <div>
      <div className='row'>
        <div className='col-md-9'>
          <form css={style.items}>
            {orderItems.map((orderItem, index) => (
              <div key={orderItem.orderItemId} css={style.item} className='w-full'>
                <div className='row w-full'>
                  {/* <div className = "col-sm-2">
                <img src={ orderItem.thumbnailUrl} alt = "immmm" />
              </div> */}

                  <div className='col-12 col-md-8'>
                    <b dangerouslySetInnerHTML={{ __html: orderItem.itemName }}></b>
                    <ItemVariantSummary orderItem={orderItem} />
                    <ItemExt orderItem={orderItem} />
                  </div>

                  {/* <div className='col-sm-2'>Qty: {render('quantity', items[index])}</div> */}

                  <div className='col-12 col-md-4' style={{ display: 'flex', alignItems: 'center' }}>
                    {DiscountedAmount('sku', orderItem.orderItemId, itemAmount(items[index], orderItem))}
                    <Button
                      btnStyle='outline-dark ml-4'
                      className='btn-sm'
                      onClick={(e) => remove(e, index)}
                      status={form.status}
                    >
                      Remove
                    </Button>
                  </div>
                </div>
              </div>
            ))}
            {taxLines.map((orderItem, index) => (
              <div key={orderItem.orderItemId} css={style.item}>
                <div className='row w-full'>
                  <div className='col-12 col-sm-8'>
                    <div css={style.product}>Tax: {orderItem.itemName}</div>
                  </div>
                  <div className='col-6 col-sm-4'>
                    {DiscountedAmount(
                      orderItem.itemName === 'GST' ? 'gst' : '',
                      orderItem.orderItemId,
                      orderItem.unitPrice
                    )}
                  </div>
                </div>
              </div>
            ))}
          </form>
          <PromoApplied cart={cart} applyPromoCode={applyPromoCode} />
          <PromoForm cart={cart} applyPromoCode={applyPromoCode} />
        </div>
        <div className='col-md-3'>
          <CartSummary cart={cart} />
        </div>
        <div className='col-md-9 form__actions' style={{ textAlign: 'center' }}>
          <Button onClick={next} status={form.status}>
            Next
          </Button>
          <ErrorMessage errors={form.errors} summaryMessage='Please review the errors.' />
        </div>
      </div>
    </div>
  );
}

function ItemExt({ orderItem }) {
  // console.log(orderItem.ext);
  const ext = orderItem?.ext;
  if (!ext) return '';
  const desc = ext.itemName;
  if (ext.itemType === 'guide')
    return <div className='text-sm text-green-700' dangerouslySetInnerHTML={{ __html: desc }}></div>;
  else if (ext.itemType === 'event')
    return (
      <div
        className='text-sm text-green-700'
        dangerouslySetInnerHTML={{ __html: desc.replace('] ', '] <br/> ') }}
      ></div>
    );
  else if (ext.itemName)
    return <div className='text-sm text-green-700' dangerouslySetInnerHTML={{ __html: desc }}></div>;

  return '';
}
