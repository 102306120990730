import utils from 'utils';
import { utcToZonedTime, format } from 'date-fns-tz';
import Timespan from 'readable-timespan';

export const baseTimeZone = 'Australia/Sydney';
export function getAESTMonth(date) {
  const zonedTime = utcToZonedTime(date, baseTimeZone);
  return {
    title: format(zonedTime, 'MMMM yyyy', { timeZone: baseTimeZone }),
    id: format(zonedTime, 'yyyy-MM', { timeZone: baseTimeZone }),
  };
}

export function getAESTDate(date, formatStr) {
  return getZonedTimeFormat(date, baseTimeZone, formatStr);
}

export function getZonedTimeFormat(date, timeZone, formatStr = 'yyyy-MM-dd HH:mm:ss zzz') {
  if (!date) return null;
  const zonedTime = utcToZonedTime(date, timeZone);
  return format(zonedTime, formatStr, { timeZone });
}

export function getTimeTextForTimeZone(fromDate, toDate, timeZone) {
  const formatFn = (date) => (getZonedTimeFormat(date, timeZone, 'h:mmaaa') || '').replace(':00', '');
  if (!fromDate) return '';
  if (!toDate || toDate <= fromDate) return formatFn(fromDate);
  return `${formatFn(fromDate)} to ${formatFn(toDate)}`;
}

export function getCurrentTimeZone() {
  try {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  } catch (e) {}
  return null;
}

export function timeLeft(date) {
  const timespan = new Timespan();
  return timespan.parse(date - new Date());
}

// https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
// timeTest('2021-10-30T13:00:00+11:00');
export function timeTest(dateObj) {
  const date = utils.date.toDate(dateObj);
  console.log(dateObj);
  console.log('Local', date);
  console.log('Adelaide', getZonedTimeFormat(date, 'Australia/Adelaide'));
  console.log('Sydney', getZonedTimeFormat(date, 'Australia/Sydney'));
  console.log('Brisbane', getZonedTimeFormat(date, 'Australia/Brisbane'));
  console.log('Darwin', getZonedTimeFormat(date, 'Australia/Darwin'));
  console.log('Perth', getZonedTimeFormat(date, 'Australia/Perth'));
}
