/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import env from 'env';

import { SiteLink } from 'components';

import { getEditorStyle } from 'site/_editor/editorStyle';
import { useGet } from 'components';
import { usePost } from 'components';
import { useForm } from 'form';
import { Button, ErrorMessage } from 'components';
import { Loading, ErrorPopup } from 'components';

import { fb, validators } from 'form';

const s = getEditorStyle();

export function ChangePassword() {
  const get = useGet();

  React.useEffect(() => {
    get.send(env.apiBase + `/api/member/getProfile`);
    // eslint-disable-next-line
  }, []);

  if (get.loading()) {
    return <Loading />;
  } else if (get.hasErrors()) {
    return <ErrorPopup title='Loading Error' errors={get.errors} />;
  }

  const data = get.response;

  return (
    <div className='container' style={{ paddingBottom: '6rem' }}>
      <h2>Change Password</h2>
      <div css={s.returnLink}>
        <SiteLink to='/member/dashboard'>&lt; Return to dashboard</SiteLink>
      </div>
      <SessionEditForm profile={data} />
    </div>
  );
}

function SessionEditForm({ profile }) {
  const [sendingStatus, setSendingStatus] = React.useState(null);

  const post = usePost();
  const model = getFormData(profile);
  const form = useForm(model, {});

  function save(e) {
    e.preventDefault();
    form.validateForm(
      e,
      () => {
        const value = { ...form.getValue() };

        post.send(env.apiBase + '/api/member/UpdatePassword', value);
        setSendingStatus('pending');
      },
      (errors) => {}
    );
  }

  if (post.done() && sendingStatus === 'pending') {
    form.reset(getFormData({}));
    setSendingStatus(null);
  }

  const render = (name) => form.renderControl(name, null);

  return (
    <div css={s.formBlock}>
      <form onSubmit={save}>
        {/* <div className="row">
          <div className="col-md-12">{render('currentPassword')}</div>
        </div> */}
        <div className='row'>
          <div className='col-md-12'>{render('password')}</div>
        </div>
        <div className='row'>
          <div className='col-md-12'>{render('confirmPassword')}</div>
        </div>
        <div className='row'>
          <div className='col-md-12'>
            <div className='form__actions' css={s.actions}>
              <Button onClick={(e) => save(e)} status={form.status}>
                Change
              </Button>
            </div>

            <ErrorMessage errors={form.errors} summaryMessage='Please review the errors.' />
            <ErrorMessage errors={post.errors} />
            {post.done() && (
              <div>
                <div style={{ color: 'chocolate', fontWeight: 'bold' }}>Password successfully reset!</div>
                <div>
                  Go back to <SiteLink to='/member/dashboard'>dashboard</SiteLink>.
                </div>
                {/* <div>Please <SiteLink to="/member/login">login</SiteLink> again.</div> */}
              </div>
            )}
          </div>
        </div>
      </form>
    </div>
  );
}

export function getFormData(data) {
  const model = fb.group({
    // currentPassword: [data.currentPassword || '', [validators.Required()], { label: 'Current Password', type: 'password' }],
    password: [
      data.password || '',
      [validators.Required(), validators.Length('Min 7 characters', { min: 7 })],
      { label: 'New Password', type: 'password' },
    ],
    confirmPassword: [
      data.confirmPassword || '',
      [validators.Required()],
      { label: 'Confirm Password', type: 'password' },
    ],
  });

  return model;
}
