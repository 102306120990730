/** @jsx jsx */
import { jsx } from '@emotion/core';

export function PromoApplied({ order }) {
  const promoStyle = {
    promoSection: {
      margin: '1rem 0',
      padding: '0.5rem 1rem',
      background: '#fff5e2',
    },
  };

  if (!(order.promoInfo && order.promoInfo.promoCode)) return null;

  // console.log(isValid, dirtyFields, isSubmitting, errors);
  return (
    <div css={promoStyle.promoSection}>
      Promo <b>{order.promoInfo.promoCode}</b>({order.promoInfo.promoDesc}) has been applied.
    </div>
  );
}
