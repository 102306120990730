/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import env from 'env';
import { Button, ErrorMessage } from 'components';
import { usePost } from 'components';
import { useStore } from 'store';
import { useRouter } from 'components';

export function AddToCart({ product, useSlider, disableLink }) {
  const post = usePost();
  const { state, dispatch } = useStore();
  const { push } = useRouter();

  const exists = React.useMemo(() => checkExists(state, product), [state, product]);

  function addToCart(e) {
    e.preventDefault();
    e.stopPropagation();
    post.send(env.apiBase + '/api/guide/addToCart', { guideId: product.guideId });
  }

  function removeFromCart(e) {
    e.preventDefault();
    e.stopPropagation();
    post.send(env.apiBase + '/api/guide/removeFromCart', { guideId: product.guideId });
  }

  React.useEffect(() => {
    if (post.done()) {
      post.reset();
      const cartInfo = post.response.results.cartInfo;
      console.log(cartInfo);
      env.setDevCartId(cartInfo.cartId);
      dispatch({
        type: 'SET_CART',
        payload: cartInfo,
      });
    }
  }, [dispatch, post, post.status]);

  return (
    <div>
      {exists && (
        <Button className='btn btn-info btn-sm' onClick={removeFromCart} status={post.status}>
          REMOVE
        </Button>
      )}
      {!exists && (
        <Button className='btn btn-info btn-sm' onClick={addToCart} status={post.status}>
          ADD TO CART
        </Button>
      )}
      {exists && (
        <button className='btn btn-warning btn-sm ml-2' onClick={() => push('/cart')}>
          GO TO CART
        </button>
      )}

      <ErrorMessage errors={post.errors} />
    </div>
  );
}

function checkExists(state, product) {
  const orderItems = state?.cartInfo?.orderItems || [];
  const exists = orderItems.some((x) => x?.ext?.itemId === product.guideId);

  //console.log('checkExists', product.guideId, state, exists);
  return exists;
}
