/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import env from 'env';
//import { GuideTiles } from 'site/members/guide/guideTiles';
import { CategoryTiles } from 'site/members/category/categoryTiles';
import { useGet } from 'components';
import { Loading, ErrorPopup } from 'components';

export function GuideTilesLoadingV2({ pageUrl }) {
  const get = useGet();

  React.useEffect(() => {
    get.send(
      `${env.apiBase}/api/member/getcategoryv2?cat=${encodeURIComponent(pageUrl)}&corporateId=${localStorage.getItem(
        'corporateId'
      )}`
    );
    // eslint-disable-next-line
  }, []);

  if (get.loading()) {
    return <Loading />;
  } else if (get.hasErrors()) {
    return <ErrorPopup title='Loading Error' errors={get.errors} />;
  }

  const data = get.response;
  //console.log("dddddddddd", data);
  
  if(!data){
    return(
      <div>
        No Guides
      </div>  
    )
  }

  return (
    <div>
      <CategoryTiles categories={data} />
    </div>
  );
}
