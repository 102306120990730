/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import env from '../../../../env';
import { usePost, Button, ErrorMessage } from '../../../../components';
import { useForm } from '../../../../form';
import { getSignupFormModel } from './signupFormModel';
import  utils from 'utils';
import cmsUtils from '../../cmsUtils';

export function SignupForm(props){
    
    const item = props.item;
    const cmsOption = props.cmsOption || {};
  
    const emailReceiver = cmsUtils.payload(item, 'EmailReceiver');
    const fileAttachment = cmsUtils.payload(item, 'FileAttachment') || "false";

    const cssClass = utils.classNames('cms_item', 'htmlPanel', cmsOption.isCmsEdit && 'html', item.cssClass || item.anchorName || '');

    const model = getSignupFormModel({});
    const form = useForm(model, { usePlaceholder: true });
    const [sendingStatus, setSendingStatus] = React.useState(null);
    const post = usePost();

    function onSubmit(e) {
        form.validateForm(e, () => {
          //const recaptchaValue = recaptchaRef.current.getValue();
          //const value = { ...form.getValue(), emailReceiver: emailReceiver, recaptchaValue : recaptchaValue }; 
          const value = { ...form.getValue(), emailReceiver: emailReceiver }; 
          var files = form.getFiles();
          var formData = new FormData();
          formData.append('jsonData', JSON.stringify(value))
          files.forEach(file => {
            (file.files || []).forEach(uploadFile => {
              formData.append(file.name, uploadFile)
            })
          });
          //post.send(env.apiBase + "/api/contact/send", value);
          post.sendFormData(env.apiBase + "/api/contact/signupform", formData);
          setSendingStatus('pending')
        })
    
        console.log('submitting')
    }

    if (post.done() && sendingStatus === 'pending') {
        //var value = form.getValue();
        //gtm.sendEvent('contactFormSubmitted', {contactEmail: value.email} )
        form.reset(model);
        setSendingStatus(null)
    }

    const render = (name) => form.renderControl(name, null);
    
    return( 
      <div className={cssClass} data-cms-html="true" data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName}>
        <div className="container contact-wrapper" id="signupForm">
        <form className="contactForm">
        <div className="form-row">
        <div className="col-md-4 mb-2">{render('name')}</div>
        <div className="col-md-4 mb-2">{render('email')}</div>
        <div className="col-md-4 mb-2">{render('phone')}</div>
      </div>

      <div className="form-row">
        <div className="col-md-12 mb-2">{render('state')}</div>
      </div>

      {/* <div className="form-row">
        <div className="col-md-12 mb-2">{render('enquiry')}</div>
      </div> */}

      { fileAttachment === 'true' &&
        <div className="form-row">
          <div className="col-md-12 mb-2"> {render('attachments')} </div>
        </div>
      }

      {/* <div className="form-row">
        <div className="col-md-12 mb-2 recapthca-container">
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={env.recaptchaSiteKey}>
          </ReCAPTCHA>
        </div>
      </div> */}

      <div className="form-row">
        <div className="col-md-12 mb-2">
          <div className="actions">
            <Button onClick={onSubmit} status={post.status}> Sign Up </Button>
            <ErrorMessage errors={form.errors} summaryMessage="Please review the errors." />
            <ErrorMessage errors={post.errors} />
            {post.done() && <span style={{marginLeft: '1.5rem', color:'red', fontWeight: 'bold'}}>Successfully sent!</span>}
          </div>
        </div>
      </div>
    </form>
        </div>
      </div>
        
        
    )

}