import React from 'react';
import { Route } from 'react-router-dom';

import { MenuLoader } from './menuLoader';

const DashboardLayout = ({ children, ...rest }) => {
  return <div className='pt-24'>{children}</div>;
};

const DashboardNoAuthLayoutRoute = ({
  component: RenderComponent,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <DashboardLayout>
          <MenuLoader />
          <RenderComponent {...matchProps} />
        </DashboardLayout>
      )}
    />
  );
};

export default DashboardNoAuthLayoutRoute;
