import React from 'react';
import { SiteLink } from 'components';
import { getAESTDate, getTimeTextForTimeZone, getCurrentTimeZone } from 'site/_helpers/dateTime';
import utils from 'utils';
import { RegisterPopup } from './register/registerPopup';
import { MenuLoader } from 'layout/menuLoader';

export function EventDetails({ event }) {
  const timeZones = React.useMemo(() => getTimeZones(event), [event]);
  console.log(
    event,
    timeZones,
    utils.date.toDateTime(event.startDateTime),
    (utils.date.toDateTime(event.startDateTime) - new Date()) / (1000 * 60 * 60)
  );
  // const tileLink = `/event/${event.eventSlug}`;
  // const title = event.eventTitle;
  const date = getAESTDate(event.startDateTime, 'MMMM d');
  const duration = event.duration;
  const isPast = utils.date.toDateTime(event.startDateTime) - new Date() < 0;
  const fullyBooked = event.numOfPlaces && (event.usedNumOfPlaces || 0) >= event.numOfPlaces;

  return (
    <div className=''>
      <MenuLoader />
      <div className='my-2'>
        <SiteLink to='/events'>&lt; Return to Events</SiteLink>
      </div>
      <h1>{event.eventTitle}</h1>
      <div className='flex'>
        <div>
          <span className='text-lg font-bold'>{date}</span> <span className='ml-0.5 mr-1.5'>@</span>
        </div>
        <div>
          <span className='text-green-600 font-bold'>
            {timeZones.current.map((x) => (
              <span key={x.timeZone}>
                {x.timeText} <span>{x.area}</span>
              </span>
            ))}
          </span>
          <span>({utils.site.toHoursText(duration)})</span>
          <div>
            {timeZones.others.map((x, inx) => (
              <span key={x.timeZone} className='inline-block text-gray-700 text-sm font-bold'>
                {inx > 0 && <span className='ml-1.5 mr-0.5'> | </span>}
                {x.timeText} <span>{x.area}</span>
              </span>
            ))}
          </div>
        </div>
      </div>

      <hr className='my-4' />

      <div dangerouslySetInnerHTML={{ __html: event.description }}></div>

      <hr className='my-4' />

      {isPast || fullyBooked ? (
        <div className=''>
          <button type='button' disabled className='btn btn-lg btn-warning'>
            {fullyBooked ? 'Fully Booked' : 'Not Available'}
          </button>
        </div>
      ) : (
        <Book event={event} />
      )}
    </div>
  );
}

function Book({ event }) {
  const [showPopup, setShowPopup] = React.useState(false);

  let priceEl = null;
  if (event.eventType === 0 /*PurchaseTicket*/) {
    priceEl = (
      <div className='pr-8'>
        <div className='font-bold text-3xl'>
          {(event.price || 0) === 0 ? 'FREE' : utils.money.formatMoney(event.price)}
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className='flex items-center max-w-md'>
        {priceEl}
        <div className=''>
          <button type='button' className='btn btn-lg btn-primary' onClick={() => setShowPopup(true)}>
            REGISTER NOW
          </button>
        </div>
      </div>
      <RegisterPopup
        showPopup={showPopup}
        onClosed={(e) => {
          e.stopPropagation();
          setShowPopup(false);
        }}
        event={event}
      />
    </div>
  );
}

function getTimeZones(event) {
  const tz = getCurrentTimeZone() || 'Australia/Sydney';
  const timeZones = [
    { timeZone: 'Australia/Adelaide', area: 'SA' },
    { timeZone: 'Australia/Sydney', area: 'NSW/VIC/TAS' },
    { timeZone: 'Australia/Brisbane', area: 'QLD' },
    { timeZone: 'Australia/Darwin', area: 'NT' },
    { timeZone: 'Australia/Perth', area: 'WA' },
  ];

  timeZones.forEach((x) => (x.timeText = getTimeTextForTimeZone(event.startDateTime, event.endDateTime, x.timeZone)));

  return { current: timeZones.filter((x) => tz === x.timeZone), others: timeZones.filter((x) => tz !== x.timeZone) };
}
