/** @jsx jsx */
import { jsx } from '@emotion/core';
import utils from 'utils';
import { GuideTilesLoadingV2 } from 'site/members/guide/guideTilesLoadingV2';

export function NewCategoryList(props) {
  const item = props.item;
  const cmsOption = props.cmsOption || {};
  // console.log(cmsOption.pageData.pageUrl, cmsOption);
  const isCmsEdit = !!cmsOption.isCmsEdit;
  const pageUrl = cmsOption.requestPath || cmsOption.pageData.pageUrl;
  // console.log('item', item, isCmsEdit);
  const cssClass = utils.classNames('cms_item', item.cssClass || item.anchorName || '');

  return isCmsEdit ? (
    <div className={cssClass} data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName}>
      <div className='m-4 p-2 border bg-green-50'> New category List (This will list category tiles automatically)</div>
    </div>
  ) : (
    <div className={cssClass} id={item.anchorName}>
        <GuideTilesLoadingV2 pageUrl={pageUrl} />
    </div>
  );
}