import utils from '../utils';
import env from '../env';
import { isMin } from '../lib/css-emotion';

export const isMobile = () => {
  // isMin.md() ==> min-width: 768px
  return !isMin.md();
};

export function resourcePath(url) {
  if (!url) return null;
  if (url && url.indexOf('/assets/') === 0) return url;
  return utils.url.isExternalLink(url) ? url : env.resourceBase + url;
}

export function fullUrl(url) {
  return utils.url.isExternalLink(url) ? url : env.baseOrigin + url;
}

export const isNZ = env.locale === 'nz';

export function getCategoryShopUrl(pageUrl) {
  return (pageUrl || '').toLowerCase().replace('/categories/', '/categories-shop/');
}

export function isCategoryShopUrl(pageUrl) {
  return (pageUrl || '').toLowerCase().indexOf('/categories-shop/') >= 0;
}

export function toHoursText(mins) {
  const h = Math.floor(mins / 60);
  const m = mins % 60;

  if (mins === 1) return mins + ' min';
  if (mins < 60) return mins + ' mins';

  const hText = h === 1 ? '1 hr' : `${h} hrs`;
  const mText = m === 0 ? '' : m === 1 ? '1 min' : `${m} mins`;
  return mText ? hText + ' ' + mText : hText;
}
