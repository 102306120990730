// import { variables } from 'cssInJs'

export function getEditorStyle() {
  return {
    formBlock: {
      maxWidth: '1600px',
      margin: '1.5rem 0',
      '& label': {
        fontWeight: 'bold'
      },
      '& p': {
        marginBottom: '0.2rem'
      }
    },
    returnLink: {
      fontSize: '0.8rem'
    },
    actions: {
      margin: '2rem 0',
      display: 'flex',
      'button, a': {
        marginRight: '0.5rem'
      }
    },
    labelHelp: {
      fontSize: '90%'
    }
  }
}
