/** @jsx jsx */
import { jsx } from '@emotion/core';
import style from './cartStyle';
import React from 'react';
import { useStore } from 'store';

//import { SiteLink } from '../../../components';

import env from '../../../env';
import { useGet, usePost } from '../../../components';
import { useForm } from '../../../form';
import { Loading, ErrorPopup } from '../../../components';

import { useStep } from './useStep';
import { StepNav } from '../cartStep/stepNav';

import { OrderItems } from '../cartOrderItems/orderItems';
import { getOrderItemFormData } from '../cartOrderItems/orderItemFormData';
import { Shipping } from '../cartShipping/shipping';
// import { getShippingFormData } from '../cartShipping/shippingFormData';
import { Pay } from '../cartPayment/pay';
import utils from '../../../utils';

export function Cart() {
  const [sendingStatus, setSendingStatus] = React.useState(null);
  const [cart, setCart] = React.useState(null);

  const get = useGet();

  React.useEffect(() => {
    get.send(env.apiBase + '/api/cart/getCart/' + env.getDevCartId());
    setSendingStatus('pending');
    // eslint-disable-next-line
  }, []);

  if (get.loading()) {
    return <Loading />;
  } else if (get.hasErrors()) {
    return <ErrorPopup title='Loading Error' errors={get.errors} />;
  }

  if (sendingStatus === 'pending') {
    setCart(get.response);
    setSendingStatus(null);
  }

  if (!cart || cart.cartInfo.totalQuantity === 0) {
    return <EmptyCart />;
  }
  return <CartView cart={cart} setCart={setCart} />;
}

function CartView({ cart, setCart }) {
  const { dispatch } = useStore();
  const [sendingStatus, setSendingStatus] = React.useState(null);
  const [goNext, setGoNext] = React.useState(false);
  const post = usePost();
  const stepInfo = useStep([
    { id: 'orderItems', title: '1. Cart' },
    { id: 'shipping', title: '2. Your Details' },
    { id: 'payment', title: '3. Pay' },
  ]);
  const { steps, currentStep, lastStep, moveNext, markAsFilled } = stepInfo;

  const orderItemForm = useForm(getOrderItemFormData(cart), { onControlChanged: orderItemChanged });
  // const shippingForm = useForm(getShippingFormData(cart), { onControlChanged: orderItemChanged });

  function orderItemChanged(e, control) {
    // if (control && control.name === 'quantity') {
    //   const index = orderItemForm.findControl('items').findIndexById(control.parent.id);
    //   const orderItem = cart.orderItems[index];
    //   const value = { qty: control.value, orderItemId: orderItem.orderItemId, cartId: env.getDevCartId() };
    //   post.send(env.apiBase + '/api/cart/updateQty', value);
    //   setSendingStatus('pending');
    //   setGoNext(false);
    // } else if (control && (control.name === 'shippingMethod' || control.name === 'shippingCountry')) {
    //   shippingForm.findControl('useSameAddress').value = false;
    //   if (control.value === 'Standard') {
    //     shippingForm.findControl('useSameAddress').value = true;
    //   }
    //   const value = { ...shippingForm.getValue(), cartId: env.getDevCartId() };
    //   post.send(env.apiBase + '/api/cart/saveShippingInfo', value);
    //   setSendingStatus('pending');
    //   setGoNext(false);
    // }
  }

  function remove(e, index) {
    console.log(index);
    const orderItem = cart.orderItems[index];
    const value = { orderItemId: orderItem.orderItemId, cartId: env.getDevCartId() };
    post.send(env.apiBase + '/api/cart/removeItem', value);
    setSendingStatus('pending');
    setGoNext(false);
  }

  function saveShipping(model) {
    const value = { ...model, cartId: env.getDevCartId() };
    post.send(env.apiBase + '/api/cart/saveShippingInfo', value);
    setSendingStatus('pending');
    setGoNext(true);
  }

  function applyPromoCode(cart) {
    setCart(cart);
  }

  React.useEffect(() => {
    if (post.done() && sendingStatus === 'pending') {
      const cart = post.response.results.cartModel;
      setSendingStatus(null);
      setCart(cart);
      if (goNext) {
        markAsFilled(currentStep);
        moveNext();
      }

      dispatch({
        type: 'SET_CART',
        payload: post.response.results.cartInfo,
      });
    }
    // eslint-disable-next-line
  }, [post, currentStep, dispatch]);

  const stepStyle = (index) => {
    const locStyle =
      index === currentStep
        ? style.stepCurrent
        : index === currentStep - 1
        ? style.stepPrev
        : index === currentStep + 1
        ? style.stepNext
        : style.step;
    const movingStyle = index === lastStep ? style.stepLast : null;
    return [locStyle, movingStyle];
  };

  const next = () => {
    markAsFilled(currentStep);
    moveNext();
    utils.ui.scrollTo('#ecmcart');
  };

  return (
    <div css={style.cart} id='ecmcart'>
      <div css={style.stepsWrapper}>
        <StepNav stepInfo={stepInfo} />
        <div css={style.steps} style={{ transform: `translateX(${-100 * currentStep}%)` }}>
          {steps.map((step, index) => {
            let stepView = null;
            switch (step.id) {
              case 'orderItems':
                stepView = (
                  <OrderItems
                    cart={cart}
                    form={orderItemForm}
                    moveNext={next}
                    post={post}
                    remove={remove}
                    applyPromoCode={applyPromoCode}
                  />
                );
                break;
              case 'shipping':
                stepView = <Shipping cart={cart} saveShipping={saveShipping} post={post} />;
                break;
              case 'payment':
                stepView = <Pay cart={cart} post={post} />;
                break;
              default:
                break;
            }
            return (
              <div css={stepStyle(index)} key={step.id}>
                {stepView}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

function EmptyCart() {
  return (
    <div css={style.cart}>
      <div css={style.stepsWrapper}>
        <h3>Your cart is empty.</h3>
      </div>
    </div>
  );
}
