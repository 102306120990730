/** @jsx jsx */
import { jsx } from '@emotion/core';
// import cmsUtils from '../../cmsUtils';
import utils from 'utils';
import { GuideTilesLoading } from 'site/members/guide/guideTilesLoading';
import { GuideTilesLoading as EcmGuideTilesLoading } from 'site/ecm/guide/guideTilesLoading';

export function GuideList(props) {
  const item = props.item;
  const cmsOption = props.cmsOption || {};
  // console.log(cmsOption.pageData.pageUrl, cmsOption);
  const isCmsEdit = !!cmsOption.isCmsEdit;
  const pageUrl = cmsOption.requestPath || cmsOption.pageData.pageUrl;
  // console.log('item', item, isCmsEdit);

  const cssClass = utils.classNames('cms_item', item.cssClass || item.anchorName || '');
  const isCategoryShopUrl = utils.site.isCategoryShopUrl(pageUrl);
  // console.log(isCategoryShopUrl, pageUrl);

  return isCmsEdit ? (
    <div className={cssClass} data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName}>
      <div className='m-4 p-2 border bg-green-50'>Guide List (This will list guide tiles automatically)</div>
    </div>
  ) : (
    <div className={cssClass} id={item.anchorName}>
      {isCategoryShopUrl ? <EcmGuideTilesLoading pageUrl={pageUrl} /> : <GuideTilesLoading pageUrl={pageUrl} />}
    </div>
  );
}
